import React, { useState } from "react";

const ProfileCard = ({ stuData }: any) => {

  const { first_name, photo, last_name } = stuData;

  const lessons = [
    { tutor: "Meenal", date: "May 25, 2024 | 18:30", type: "Trial" },
    { tutor: "Maya", date: "May 25, 2024 | 18:30", type: "Trial" },
    { tutor: "Liam", date: "May 25, 2024 | 18:30", type: "Trial" },
  ];
  const getGreeting = () => {
    const hour = new Date().getHours();

    if (hour < 12) {
      return 'Good Morning';
    } else if (hour < 18) {
      return 'Good Afternoon';
    } else if(hour < 22) {  
      return 'Good Evening';
    }
    return 'Good Night';
  };

  const [activeTab, setActiveTab] = useState("upcoming");

  // Filter lessons based on the active tab
  const filteredLessons = lessons.filter(
    (lesson) =>
      lesson.type === (activeTab === "upcoming" ? "Trial" : "Completed")
  );

  return (
    <div className="bg-white p-4 space-y-5 rounded-lg shadow">
      <div className="flex items-center">
        <h1 className="text-[#000606] text-2xl">Profile</h1>
      </div>
      <div className="flex flex-col gap-4 justify-center items-center">
        {
          photo ?
            <div className="rounded-full border w-40 h-40 flex items-center justify-center bg-white">
              <img
                src={photo ? photo : '/images/profile.png'}
                alt={`${first_name}'s profile`}
                className="w-40 h-40 rounded-full object-cover"
              />
            </div> :
            <div className="w-32 h-32 bg-[#e5dfe9] text-black text-2xl rounded-full flex items-center justify-center">
              {
                first_name ? first_name.charAt(0) + last_name.charAt(0) : 'U'
              }
            </div>
        }
        <div className="ml-4">
          <h2 className="text-lg font-semibold">{getGreeting()} {first_name ? first_name : 'User'}</h2>
        </div>
      </div>
      {/* APPLY FOR JOBS */}
      <div>
        <h2 className="text-lg font-bold">Your Schedule</h2>
        <div className="flex border-b mt-4">
          <button
            className={`flex-1 text-center py-2 font-semibold ${activeTab === "upcoming"
              ? "border-b-2 border-green-500"
              : "text-gray-500"
              }`}
            onClick={() => setActiveTab("upcoming")}
          >
            Upcoming Lessons
          </button>
          <button
            className={`flex-1 text-center py-2 font-semibold ${activeTab === "past"
              ? "border-b-2 border-green-500"
              : "text-gray-500"
              }`}
            onClick={() => setActiveTab("past")}
          >
            Past Lessons
          </button>
        </div>

        {/* Lessons List */}
        <div className="bg-gray-50 p-4 mt-4 rounded-lg space-y-4">
          <div className="flex justify-between items-center">
            <h1 className="text-base">Tutor</h1>
            <h1 className="text-base">Type</h1>
          </div>
          {filteredLessons.length > 0 ? (
            filteredLessons.map((lesson: any, index: number) => (
              <div
                key={index}
                className="flex justify-between items-center border-b border-dashed border-[#008847] pb-2 last:border-b-0"
              >
                <div>
                  <h3 className="font-medium text-sm">{lesson.tutor}</h3>
                  <p className="text-sm text-gray-600">{lesson.date}</p>
                </div>
                <span className="px-3 py-1 text-sm text-white bg-[#E2B133] rounded-full">
                  {lesson.type}
                </span>
              </div>
            ))
          ) : (
            <p className="text-gray-500 text-center">No lessons found.</p>
          )}
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
