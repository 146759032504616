import { useState } from "react"
import { Bell, Search, Send } from "lucide-react"
import demo from "../../images/asserts/Dashboard Assets/demo.png"
interface Message {
    id: number
    text: string
    sender: "user" | "other"
    timestamp: string
}

export default function InboxMessages() {
    const [activeTab, setActiveTab] = useState<"latest" | "achieved">("latest")
    const [messages, setMessages] = useState<Message[]>([
        {
            id: 1,
            text: "I was asking for your New Year Plans, ask we are going to host a party.",
            sender: "other",
            timestamp: "12:30",
        },
        {
            id: 2,
            text: "I was asking for your New Year Plans, ask we are going to host a party.",
            sender: "user",
            timestamp: "12:35",
        },
    ])
    const [newMessage, setNewMessage] = useState("")

    const sendMessage = (e: React.FormEvent) => {
        e.preventDefault()
        if (!newMessage.trim()) return

        setMessages([
            ...messages,
            {
                id: Date.now(),
                text: newMessage,
                sender: "user",
                timestamp: new Date().toLocaleTimeString("en-US", {
                    hour: "2-digit",
                    minute: "2-digit",
                    hour12: false,
                }),
            },
        ])
        setNewMessage("")
    }

    return (
        <div className="relative">
            <div className="flex justify-between items-center mb-10">
                <h1 className="text-[#000606] font-normal text-3xl">Inbox</h1>
                <div className="flex items-center gap-3">
                    <div className="p-2 bg-white rounded-full w-fit">
                        <Bell />
                    </div>
                    <div className="flex items-center bg-white rounded-[24px] py-1 px-2 gap-2">
                        <img
                            src={demo}
                            alt='Hassan'
                            className="w-9 h-9 rounded-full object-cover"
                        />
                        <h1 className="text-sm text-[#292D32] font-normal">
                            Hassan
                        </h1>
                    </div>
                </div>
            </div>
            <div className="w-full bg-white py-1 rounded-t-[20px] px-10">
                <h2 className="text-3xl mt-3">Messages</h2>
                <hr className="w-full absolute left-0  border-black" />
            </div>
            <div className="flex bg-gray-50 border-none h-screen" >
                <div className="w-[25%] rounded-l-[20px] bg-white pt-6 flex flex-col">
                    <div className="p-3">
                        <div className="flex border-b mb-4">
                            <button
                                onClick={() => setActiveTab("latest")}
                                className={`pb-2 px-4 text-sm font-medium ${activeTab === "latest" ? "text-green-600 border-b-2 border-green-600" : "text-gray-500"
                                    }`}
                            >
                                Latest Messages
                            </button>
                            <button
                                onClick={() => setActiveTab("achieved")}
                                className={`pb-2 px-4 text-sm font-medium ${activeTab === "achieved" ? "text-green-600 border-b-2 border-green-600" : "text-gray-500"
                                    }`}
                            >
                                Achieved
                            </button>
                        </div>

                        {/* Search */}
                        <div className="relative mb-4">
                            <input
                                type="text"
                                placeholder="Search Chat"
                                className="w-full pl-4 py-3 bg-transparent border border-gray-300 rounded-xl text-sm"
                            />
                            <Search className="w-4 h-4 absolute right-3 top-1/2 -translate-y-1/2 text-gray-400" />
                        </div>

                        {/* Chat List */}
                        <div className="space-y-4">
                            <div className="p-3 bg-[#0088470D] border border-[#008847] rounded-xl">
                                <div className="flex items-center gap-3 mb-2">
                                    <img src={demo} alt="Hassan" className="w-10 h-10 rounded-full" />
                                    <div className="flex justify-between items-center w-full">
                                        <h3 className="font-medium text-xl">Hassan</h3>
                                        <p className=" text-gray-500">12:30</p>
                                    </div>
                                </div>
                                <div className="flex gap-2 mt-2">
                                    <button className="px-4 py-1 bg-[#008847] text-white text-sm rounded">Free Fit Trial</button>
                                    <button className="px-4 py-1 bg-[#ED1D24] text-white text-sm rounded">Instant Book</button>
                                </div>
                                <button className="w-full mt-2 py-1 border border-[#008847] rounded text-sm bg-[#0088471A]">Achieve</button>
                            </div>
                        </div>
                    </div>
                </div>

                {/* Main Chat Area */}
                <div className="flex-1 flex flex-col">
                    {/* Messages */}
                    <div className="flex-1 overflow-y-auto p-4">
                        <div className="bg-white w-full rounded-xl">
                            <div className="p-4 border mb-2 bg-white flex items-center justify-between rounded-xl">
                                <div className="flex items-center gap-3">
                                    <img src={demo} alt="Hassan" className="w-10 h-10 rounded-full" />
                                    <h2 className="font-medium text-2xl">Hassan</h2>
                                </div>
                                <button className="px-4 py-2 bg-[#008847] text-white rounded-xl">View Profile</button>
                            </div>
                        </div>
                        <div className="space-y-4">
                            <div className="text-center text-sm text-gray-500">Today</div>
                            {messages.map((message) => (
                                <div key={message.id} className={`flex ${message.sender === "user" ? "justify-end" : "justify-start"}`}>
                                    <div className="flex items-start gap-2 max-w-[50%] relative">
                                        {message.sender === "other" && (
                                            <img src={demo} alt="" className="w-8 h-8 rounded-full" />
                                        )}
                                        <div
                                            className={`rounded-xl p-3 ${message.sender === "user" ? "bg-[#008847] text-white" : "bg-[#F1F1F1] border"
                                                }`}
                                        >
                                            <p>{message.text}</p>
                                        </div>
                                        <div className={`absolute -bottom-9 ${message.sender === "user" ? "right-12" : "left-12"} `}>
                                            <p className={`text-xs  text-black`}>
                                                {message.timestamp}
                                            </p>
                                        </div>
                                        {message.sender === "user" && (
                                            <img src={demo} alt="" className="w-8 h-8 rounded-full" />
                                        )}
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>

                    {/* Message Input */}
                    <form onSubmit={sendMessage} className="p-4 bg-white border-t">
                        <div className="relative">
                            <input
                                type="text"
                                value={newMessage}
                                onChange={(e) => setNewMessage(e.target.value)}
                                placeholder="Type a message to send"
                                className="w-full pl-4 pr-12 py-3 bg-gray-50 rounded-lg"
                            />
                            <button
                                type="submit"
                                className="absolute right-2 top-1/2 -translate-y-1/2 p-2"
                            >
                                <Send className="w-5 h-5 text-[#008847]" />
                            </button>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    )
}

