import React, { useState } from "react";
import videoimg from "../../../images/Homepageassets/7.png";
import { BadgeAlert } from "lucide-react";

function RecordVideo() {
  const [link, setLink] = useState("");

  const handleInputChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setLink(e.target.value);
  };

  return (
    <div className="space-y-8">
      <div>
        <h1 className="text-2xl text-[#272727] font-semibold">
          Record a Video
        </h1>
      </div>
      <div className="space-y-5">
        <div className="flex items-center gap-2 text-sm font-medium text-gray-700">
          Introduce Yourself With A Video!
          <BadgeAlert className="text-[#008847]" />
        </div>
        <img src={videoimg} alt="Videoimg" />
        {/* BUTTON */}
        <div className="flex items-center gap-5">
          <button className="border-2 border-[#008847] bg-[#e5f3ed] hover:border-4 hover:bg-[#008847] hover:border-[#66b891] hover:text-white rounded-[40px] text-[#008847] text-base font-semibold py-2 px-5">
            Record Video
          </button>
          <button className="border-2 border-[#008847] bg-[#e5f3ed] hover:border-4 hover:bg-[#008847] hover:border-[#66b891] hover:text-white rounded-[40px] text-[#008847] text-base font-semibold py-2 px-5">
            Watch a Demo Video
          </button>
        </div>
      </div>

      <div className="space-y-4">
        <h1 className="text-sm font-medium text-gray-700">
          Upload your YouTube or Vimeo link
        </h1>
        <div className="border border-gray-200 rounded-lg flex items-center justify-between max-w-md w-full">
          <input
            type="text"
            value={link}
            onChange={handleInputChange}
            className="w-full p-2 text-base font-medium text-[#272727] focus:outline-none"
            placeholder="Paste your link"
          />
          <button className="bg-[#008847] text-white py-3 px-4 rounded-r-lg">
            Upload
          </button>
        </div>
      </div>
    </div>
  );
}

export default RecordVideo;
