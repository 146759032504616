import { gsap } from "gsap";
import { useEffect, useRef, useState } from "react";
import axios from "axios";
import bgimage from "../../images/AboutImages/Vector.png";
import { useParams } from "react-router-dom";

function TutorVideo() {
  const { id } = useParams();
  const movingImageRef = useRef(null);
  const [videoLink, setVideoLink] = useState(null);
  const [error, setError] = useState(null);

  const getYouTubeEmbedURL = (url) => {
    const regExp = /(?:https?:\/\/)?(?:www\.)?(?:youtube\.com\/(?:watch\?v=|shorts\/)|youtu\.be\/)([\w-]{11})/;
    const match = url.match(regExp);
    const videoId = match ? match[1] : null;
    return videoId ? `https://www.youtube.com/embed/${videoId}` : null;
  };

  useEffect(() => {
    const fetchVideoLink = async () => {
      try {
        const response = await axios.get(
          `https://api.nadwa-uae.com/api/v2/tutor/${id}`
        );
        const link = response.data.video_link;
        const embedLink = getYouTubeEmbedURL(link);
        if (embedLink) {
          setVideoLink(embedLink);
        } else {
          setError("Invalid video link.");
        }
      } catch (error) {
        setError("Failed to fetch video link.");
        console.error("Error fetching video link:", error.message);
      }
    };

    fetchVideoLink();
  }, [id]);

  useEffect(() => {
    const handleScroll = () => {
      const scaleValue = 1 + window.scrollY * 0.001;
      gsap.to(movingImageRef.current, {
        scale: scaleValue,
        duration: 0.3,
        ease: "power2.out",
      });
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  if (videoLink) {
    return (
      <div className="flex flex-col items-center justify-center backdrop-blur-md h-[500px] relative overflow-hidden">
        {/* Background Image */}
        <img
          src={bgimage}
          alt="Background Tutor"
          className="rounded-[20px] w-96 absolute -z-10"
        />

        {/* Moving Image */}
        <img
          src={bgimage} // Use the background image as a placeholder
          alt="Moving Tutor"
          ref={movingImageRef}
          className="rounded-[20px] w-52 h-32 z-10"
        />

        {/* Video Embed */}
        {videoLink ? (
          <iframe
            width="560"
            height="315"
            src={videoLink}
            title="YouTube video player"
            frameBorder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
            allowFullScreen
          ></iframe>
        ) : (
          <p>{error || "Loading video..."}</p>
        )}
      </div>
    );
  } else {
    return null;
  }
}

export default TutorVideo;
