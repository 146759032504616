import React, { useEffect, useRef, useState } from "react";
import { MoveLeft, MoveRight } from "lucide-react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Autoplay, Navigation } from "swiper/modules";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/autoplay";
import firstImage from "../../images/Homepageassets/1.png";
import secondImage from "../../images/Homepageassets/2.png";
import thirdImage from "../../images/Homepageassets/3.png";
import fourthImage from "../../images/Homepageassets/6thSectionBg.png";
import { useTranslation } from "react-i18next";

export default function Section6() {
  const [isPopupVisible, setPopupVisible] = useState(false);
  const [activeSlide, setActiveSlide] = useState(0);

  // Function to handle the button click and toggle popup visibility
  const handleButtonClick = () => {
    setPopupVisible(true);
  };

  const off = () => {
    setPopupVisible(false);
  };

  const { t, i18n }: { t: (key: string) => string, i18n: any } = useTranslation();
  const isArabic = i18n.language === "ar";
  const prevRef = useRef<HTMLButtonElement>(null);
  const nextRef = useRef<HTMLButtonElement>(null);

  // Add a scroll event listener to close the popup on scroll
  useEffect(() => {
    const handleScroll = () => {
      if (isPopupVisible) {
        setPopupVisible(false);
      }
    };

    window.addEventListener("scroll", handleScroll);

    // Cleanup the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isPopupVisible]);

  return (
    <div
      className="2xl:w-full 2xl:h-[110vh] relative bg-cover bg-center shadow pb-20"
      style={{
        backgroundImage: `url(${fourthImage})`,
      }}
    >
      <div className="flex lg:flex-row flex-col items-center gap-0 2xl:my-[50%] 2xl:pt-[10%] 2xl:-mt-[0%] w-full">
        {/* Steps Section */}
        <div className="flex flex-col justify-center items-center pt-10 col-span-2 py-5 lg:px-5 px-4 2xl:w-[500px] lg:w-[700px]">
          <div>
            <div className="flex flex-row justify-start items-center gap-2">
              <div>
                <div className="w-2.5 h-2.5 -mt-1 bg-white rounded-full my-[50%] mb-1"></div>
              </div>
              <h4 className="text-white font-bold text-[20px]">{t("work")}</h4>
            </div>
            <h2 className={`text-white 2xl:text-[65px] text-[45px] font-bold ${isArabic ? "" : 'font-["Helvetica"]'}  leading-[71.50px]`}>
              {t("work2")}
            </h2>
            <h2 className={`text-white text-[35px] font-bold  ${isArabic ? "" : 'font-["Helvetica"]'}   leading-[71.50px]`}>{t("work3")}</h2>
            <p className={`text-white 2xl:text-[22px] text-[19px] font-normal ${isArabic ? "" : 'font-["Inter"] '}   leading-[30px]`}>
              {t("work4")}
            </p>
            <div className="flex lg:gap-6 gap-2 items-center ">
              <a href="/HowItWork">
                <button className={`lg:w-40 w-32 h-12 bg-slate-100 rounded-full transition-all duration-300 hover:bg-red-600 white text-black lg:text-lg font-bold ${isArabic ? "" : "font-['Helvetica']"} capitalize leading-[18px]`}>
                  {t("workbt1")}
                </button>
              </a>
              <div>
                <button
                  className={`lg:w-48 w-44 h-12 bg-[#ED1D24] rounded-full transition-all duration-300 hover:bg-black hover:text-white text-white lg:text-lg font-bold ${isArabic ? "" : "font-['Helvetica']"} capitalize leading-[18px]`}
                  onClick={handleButtonClick}
                >
                  {t("workbt2")}
                </button>
                {/* Popup (conditionally rendered based on state) */}
                {isPopupVisible && (
                  <div className="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
                    <div className="bg-gray-200 w-96 h-80 p-6 flex flex-col justify-center rounded-lg shadow-lg relative">
                      {/* Close button */}
                      <button onClick={off} className="absolute top-3 right-3 text-gray-600 hover:text-gray-900">
                        ✖
                      </button>

                      {/* Content */}
                      <p className="text-lg text-gray-700 text-center font-medium mb-6">
                        {t("popup3")}
                      </p>

                      <div className="flex items-center justify-center">
                        <a
                          href={"https://nadwa-uae.com/student/register/"}
                          onClick={off}
                          className="bg-green-600 hover:bg-green-500 no-underline text-white font-semibold px-4 py-2 rounded-md"
                        >
                          {t("popup2")}
                        </a>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>

        {/* Swiper Section */}
        <Swiper
          spaceBetween={0}
          slidesPerView={1.2}
          navigation={{
            prevEl: prevRef.current,
            nextEl: nextRef.current,
          }}
          onSlideChange={(swiper: any) => setActiveSlide(swiper.activeIndex)}
          onSwiper={(swiper: any) => {
            setTimeout(() => {
              if (prevRef.current && nextRef.current) {
                if (swiper.params.navigation && typeof swiper.params.navigation !== 'boolean') {
                  swiper.params.navigation.prevEl = prevRef.current;
                  swiper.params.navigation.nextEl = nextRef.current;
                  swiper.navigation.init();
                  swiper.navigation.update();
                }
              }
            });
          }}
          autoplay={{ delay: 3000 }}
          modules={[Navigation, Autoplay]}
          breakpoints={{
            640: { slidesPerView: 1 },
            768: { slidesPerView: 2 },
            1024: { slidesPerView: 3 },
          }}
          dir="ltr"
          className={`flex justify-center items-center col-span-1 lg:col-span-3 lg:w-[60%] w-[96%] 2xl:col-span-3`}
        >
          {/* Slides */}
          <SwiperSlide className="flex flex-col items-start justify-end rounded-xl">
            <img
              src={secondImage}
              alt="First Step"
              className="w-full h-auto object-cover rounded-xl"
            />
            <div className="text-start mt-4">
              <span className="text-white text-md font-bold">01</span>
              <div className="relative w-[90%] h-1 bg-gray-400 rounded-md my-2 after:absolute after:contents-[''] after:top-0 after:left-0 after:bg-white after:h-1 after:w-[33.33%]"></div>
              <p className="text-white text-md font-normal">
                {t('workcard1')}
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide className="flex flex-col items-center justify-end rounded-xl">
            <img
              src={thirdImage}
              alt="Second Step"
              className="w-full h-auto object-cover rounded-xl mt-10"
            />
            <div className="text-start mt-4">
              <span className="text-white text-md font-bold">02</span>
              <div className="relative w-[90%] h-1 bg-gray-400 rounded-md my-2 after:absolute after:contents-[''] after:top-0 after:left-0 after:bg-white after:h-1 after:w-[66.66%]"></div>
              <p className="text-white text-md font-normal">
                {t('workcard2')}
              </p>
            </div>
          </SwiperSlide>

          <SwiperSlide className="flex flex-col items-center justify-end rounded-xl">
            <img
              src={firstImage}
              alt="Third Step"
              className="w-full h-auto object-cover rounded-xl mt-20"
            />
            <div className="text-start mt-4">
              <span className="text-white text-md font-bold">03</span>
              <div className="relative w-[90%] h-1 bg-gray-400 rounded-md my-2 after:absolute after:contents-[''] after:top-0 after:left-0 after:bg-white after:h-1 after:w-full"></div>
              <p className="text-white text-md font-normal">
                {t('workcard3')}
              </p>
            </div>
          </SwiperSlide>
        </Swiper>

        {/* Navigation Buttons */}
        <div className="relative w-full mt-6 lg:hidden">
          <div className={`absolute top-5 lg:right-0 right-6 flex ${isArabic ? "flex-row-reverse" : ""} gap-2 items-center`}>
            <button
              ref={prevRef}
              className="lg:w-[50px] lg:h-[50px] w-10 h-10 bg-[#ffffff] rounded-full text-black flex justify-center items-center cursor-pointer"
            >
              <MoveLeft />
            </button>
            <button
              ref={nextRef}
              className="lg:w-[50px] lg:h-[50px] w-10 h-10 bg-[#ffffff] rounded-full text-black flex justify-center items-center cursor-pointer"
            >
              <MoveRight />
            </button>
          </div>
          <div className={`flex ${isArabic ? "relative right-[45%]" : ""} items-center mt-10 ml-4`}>
            <div className={`flex gap-2 w-[55%]`}>
              <div
                className={`flex-1 h-[2px] ${activeSlide === 0 ? "bg-white" : "bg-gray-400"
                  }`}
              ></div>
              <div
                className={`flex-1 h-[2px] ${activeSlide === 1 ? "bg-white" : "bg-gray-400"
                  }`}
              ></div>
              <div
                className={`flex-1 h-[2px] ${activeSlide === 2 ? "bg-white" : "bg-gray-400"
                  }`}
              ></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
