import React from 'react';

const SkeletonCard = () => {
    return (
        <div className="animate-blink flex flex-col items-center border rounded-lg shadow-lg p-4 w-[300px] md:w-[350px]">
            {/* Image placeholder */}
            <div className="bg-gray-300 rounded-full w-[100px] h-[100px] mb-4"></div>

            {/* Name placeholder */}
            <div className="bg-gray-300 h-4 w-3/4 mb-2 rounded"></div>

            {/* Subtitle placeholder */}
            <div className="bg-gray-300 h-3 w-1/2 mb-4 rounded"></div>

            {/* Price placeholder */}
            <div className="bg-gray-300 h-4 w-1/3 mb-6 rounded"></div>

            {/* Icon placeholders */}
            <div className="flex justify-between w-full mb-4">
                <div className="bg-gray-300 h-4 w-1/4 rounded"></div>
                <div className="bg-gray-300 h-4 w-1/4 rounded"></div>
            </div>

            {/* Button placeholder */}
            <div className="bg-gray-300 h-8 w-1/2 rounded"></div>
        </div>
    );
};

const SkeletonLoader = () => {
    return (
        <div className="flex flex-wrap justify-center gap-6 mt-8">
            {
                Array.from({ length: 8 }).map((_, index) => (
                    <SkeletonCard key={index} />
                ))
            }
        </div>
    );
};

export default SkeletonLoader;
