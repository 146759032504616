import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const WeeklyCalendarExact = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const [tutorData, setTutorData] = useState(null);
  const [error, setError] = useState(null);
  const [timeSlots, setTimeSlots] = useState([]);
  const totalRows = 24; // 24 hours with 30-minute intervals

  useEffect(() => {
    const fetchTutorData = async () => {
      try {
        const res = await axios.get(
          `https://api.nadwa-uae.com/api/v2/tutor/${id}/`
        );
        setTutorData(res.data);
        generateTimeSlots(res.data.availability);
      } catch (error) {
        setError("Failed to fetch tutor data. Please try again later.");
      }
    };

    fetchTutorData();
  }, [id]);

  const generateTimeSlots = (availability) => {
    const slots = [];

    availability.forEach((entry) => {
      const day = entry.day;
      const startTime = convertToMinutes(entry.start_time);
      const endTime = convertToMinutes(entry.end_time);

      for (let time = startTime; time < endTime; time += 60) {
        slots.push({
          day,
          time: `${convertToTimeString(time)} - ${convertToTimeString(
            time + 60
          )}`,
          rowStart: time / 60,
          rowSpan: 1,
          type: time < 720 ? "morning" : "evening", // Morning before 12 PM
        });
      }
    });

    setTimeSlots(slots);
  };

  const convertToMinutes = (timeString) => {
    const [hours, minutes] = timeString.split(":").map(Number);
    return hours * 60 + minutes;
  };

  const convertToTimeString = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const mins = minutes % 60;
    const period = hours < 12 ? "AM" : "PM";
    const formattedHours = hours % 12 || 12;
    return `${formattedHours}:${mins.toString().padStart(2, "0")} ${period}`;
  };

  const date = new Date();

  // Get the current day of the week (0 - Sunday, 1 - Monday, etc.)
  const currentDayOfWeek = date.getDay();

  // Update days dynamically based on the current day
  const days = [
    {
      date: date.getDate(),
      day: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][currentDayOfWeek],
      dayNumber: ((currentDayOfWeek + 6) % 7) + 1,
    },
    {
      date: date.getDate() + 1,
      day: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][
        (currentDayOfWeek + 1) % 7
      ],
      dayNumber: ((currentDayOfWeek + 7) % 7) + 1,
    },
    {
      date: date.getDate() + 2,
      day: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][
        (currentDayOfWeek + 2) % 7
      ],
      dayNumber: ((currentDayOfWeek + 1) % 7) + 1,
    },
    {
      date: date.getDate() + 3,
      day: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][
        (currentDayOfWeek + 3) % 7
      ],
      dayNumber: ((currentDayOfWeek + 2) % 7) + 1,
    },
    {
      date: date.getDate() + 4,
      day: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][
        (currentDayOfWeek + 4) % 7
      ],
      dayNumber: ((currentDayOfWeek + 3) % 7) + 1,
    },
    {
      date: date.getDate() + 5,
      day: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][
        (currentDayOfWeek + 5) % 7
      ],
      dayNumber: ((currentDayOfWeek + 4) % 7) + 1,
    },
    {
      date: date.getDate() + 6,
      day: ["SUN", "MON", "TUE", "WED", "THU", "FRI", "SAT"][
        (currentDayOfWeek + 6) % 7
      ],
      dayNumber: ((currentDayOfWeek + 5) % 7) + 1,
    },
  ];

  const getSlot = (day, rowIndex) => {
    return timeSlots.find(
      (slot) => slot.day === day && slot.rowStart === rowIndex
    );
  };

  const isSlotAvailable = (day, rowIndex) => {
    return timeSlots.some(
      (slot) =>
        slot.day === day &&
        rowIndex >= slot.rowStart &&
        rowIndex < slot.rowStart + slot.rowSpan
    );
  };

  return (
    <div className="px-10 py-10 sm:block hidden">
      {/* Header */}
      <div className="flex justify-between items-center mb-4 bg-[#F0F3F599] p-3">
        <h2 className="text-2xl font-bold">{t('WeeklyCalender')}</h2>
        <button className="bg-red-500 text-white px-4 py-2 md:hidden rounded hover:bg-red-600">
        {t('openCalender')}
        </button>
      </div>

      {/* Calendar Table */}
      <div className="overflow-x-auto">
        <table className="w-full border-collapse">
          <thead>
            <tr>
              {days.map((day, index) => (
                <th
                  key={index}
                  className="border border-gray-200 p-2 text-center"
                >
                  <div className="font-bold">{day.date}</div>
                  <div className="text-sm text-gray-500">{day.day}</div>
                </th>
              ))}
            </tr>
          </thead>
          <tbody>
            {[...Array(totalRows)].map((_, rowIndex) => (
              <tr key={rowIndex} className="h-8">
                {days.map((day) => {
                  const slot = getSlot(day.dayNumber, rowIndex);
                  const isAvailable = isSlotAvailable(day.dayNumber, rowIndex);

                  return (
                    <td
                      key={day.dayNumber}
                      className={`border border-gray-200 w-52 p-2 relative ${
                        isAvailable
                          ? ""
                          : "bg-gray-100 text-gray-400 cursor-not-allowed"
                      }`}
                    >
                      {slot ? (
                        <div
                          className={`h-full w-full font-semibold text-center rounded-md border p-2 text-sm ${
                            slot.type === "morning"
                              ? "bg-[#a8ffd5] border-green-300"
                              : "bg-[#a8d8ff] border-blue-300"
                          }`}
                        >
                          {slot.time}
                        </div>
                      ) : (
                        <span className="text-sm text-black/70 font-semibold flex justify-center">{`${convertToTimeString(
                          rowIndex * 60
                        )} - ${convertToTimeString(
                          (rowIndex + 1) * 60
                        )}`}</span>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default WeeklyCalendarExact;
