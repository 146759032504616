import React, { useEffect, useState } from "react";
import Browse1 from "../../images/asserts/Website-Assets/HowIt Work/Browse 1.svg";
import Browse2 from "../../images/asserts/Website-Assets/HowIt Work/Browse 2.svg";
import Browse3 from "../../images/asserts/Website-Assets/HowIt Work/Browse 3.svg";
import Browse4 from "../../images/asserts/Website-Assets/HowIt Work/Browse 4.svg";
import Frame1 from "../../images/asserts/Website-Assets/HowIt Work/Frame-1.svg";
import Frame from "../../images/asserts/Website-Assets/HowIt Work/Frame.svg";
import Frame2 from "../../images/asserts/Website-Assets/HowIt Work/Frame.png";
import Frame3 from "../../images/asserts/Website-Assets/HowIt Work/Ã‘Ã«Ã®Ã©_1.png";
import { useTranslation } from "react-i18next";
import { ScrollLine } from "./ScrollLine";

const Timeline = () => {
   const { t }: { t: (key: string) => string; } = useTranslation();
  const [scrollProgress, setScrollProgress] = useState(0);

  const steps = [
    {
      title: t("step1Head"),
      description: t("step1Para"),
      position: "left",
      image: Frame2,
    },
    {
      title: t("step2Head"),
      description: t("step2Para"),
      position: "right",
      image: Frame3,
    },
    {
      title: t("step3Head"),
      description: t("step3Para"),
      position: "left",
      link: t("step3Link"),
      linkText: t("step3LinPara"),
      image: Browse1,
    },
    {
      title: t("step4Head"),
      description: t("step4Para"),
      position: "right",
      image: Frame,
    },
    {
      title: t("step5Head"),
      description: t("step5Para"),
      position: "left",
      image: Browse2,
    },
    {
      title: t("step6Head"),
      description: t("step6Para"),
      position: "right",
      image: Browse3,
    },
    {
      title: t("step7Head"),
      description: t("step7Para"),
      position: "left",
      image: Browse4,
    },
    {
      title: t("step8Head"),
      description: t("step8Para"),
      position: "right",
      link: t("step8Link"),
      linkText: t("step8LinPara"),
      image: Frame1,
    },
  ];

  useEffect(() => {
    const handleScroll = () => {
      const container = document.getElementById("timeline-container");
      if (!container) return;

      const { top, height } = container.getBoundingClientRect();
      const windowHeight = window.innerHeight;
      const totalScrollDistance = height + windowHeight;

      const distanceFromTop = windowHeight - top;
      const scrollPercentage = Math.min(
        Math.max(distanceFromTop / totalScrollDistance, 0),
        1
      );

      setScrollProgress(scrollPercentage);
    };

    const handleResize = () => {
      window.location.reload();
    };

    window.addEventListener("scroll", handleScroll);
    window.addEventListener("resize", handleResize);
    handleScroll(); // Initial calculation
    return () => {
      window.removeEventListener("scroll", handleScroll);
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  const totalSteps = steps.length;
  const currentStep = Math.floor(scrollProgress * totalSteps);

  return (
    <div
      id="timeline-container"
      className="relative py-2 bg-gradient-to-t from-[#fff] to-[#d3eadf] z-20 lg:-mt-14 -mt-14 rounded-tr-[100px]"
    >
      <ScrollLine sectionId="timeline-container" />
      <div className="relative px-5 md:px-16 lg:px-40">
        {steps.map((step, index) => (
          <div
            key={index}
            className={`sm:mb-12 ${
              index <= currentStep ? "opacity-100" : "opacity-50"
            } ${index === 7 ? "opacity-100" : "opacity-50" } transition-opacity duration-500`}
          >
            <div className="flex flex-col sm:flex-row justify-between sm:items-center gap-8">
              {step.position === "left" ? (
                <>
                  <div className="custom-order sm:w-1/2">
                    <div className="max-w-md">
                      <h1 className="text-lg font-semibold text-[#ED1D24]">
                        {t("step2")} {index + 1}
                      </h1>
                      <h3 className="text-2xl md:text-4xl font-semibold text-gray-900 mb-2">
                        {step.title}
                      </h3>
                      <p className="text-gray-600 mb-2">{step.description}</p>
                      {step.link && (
                        <div className="mt-2">
                          <h4 className="font-medium text-[#008847]">
                            {step.link}
                          </h4>
                          <p className="text-gray-600">{step.linkText}</p>
                        </div>
                      )}
                    </div>
                  </div>
                  <div className="sm:w-1/2 flex justify-end">
                    <img
                      src={step.image}
                      alt={step.title}
                      className="w-64 h-64 object-contain"
                    />
                  </div>
                </>
              ) : (
                <>
                  <div className="sm:w-1/2">
                    <img
                      src={step.image}
                      alt={step.title}
                      className="w-64 h-64 object-contain"
                    />
                  </div>
                  <div className="sm:w-1/2">
                    <div className="max-w-md ml-auto">
                      <h1 className="text-lg font-semibold text-[#ED1D24]">
                        {t("step2")} {index + 1}
                      </h1>
                      <h3 className="text-2xl md:text-4xl font-semibold text-gray-900 mb-2">
                        {step.title}
                      </h3>
                      <p className="text-gray-600 mb-2">{step.description}</p>
                      {step.link && (
                        <div className="mt-2">
                          <h4 className="font-medium text-[#008847]">
                            {step.link}
                          </h4>
                          <p className="text-gray-600">{step.linkText}</p>
                        </div>
                      )}
                    </div>
                  </div>
                </>
              )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Timeline;
