import React, { useState } from "react";
import {
  Home,
  User,
  Inbox,
  Settings,
  LogOut,
  Search,
} from "lucide-react";
import LogoImage from "../../images/Homepageassets/Nadwa-Color.png";
import AccountSettings from "./Account";

const Sidebar = ({ activeTab, onTabChange }: { activeTab: string; onTabChange: (tab: any) => void }) => {
  const [isOpen, setIsOpen] = useState(false)
  const tabs = [
    { id: "dashboard", name: "Dashboard", icon: <Home /> },
    { id: "findTutor", name: "Find a Tutor", icon: <Search /> },
    { id: "inbox", name: "Inbox", icon: <Inbox /> },
    { id: "booking", name: "Bookings", icon: <User /> },
  ];
  return (
    <>
      <aside className=" bg-white rounded-lg shadow space-y-32 p-6">
        <img src={LogoImage} className=" w-40" alt="2nd Section" />
        <div className="space-y-10">
          <h1 className="font-medium text-[#808080] text-left text-sm">
            OVERVIEW
          </h1>
          <ul className="space-y-3">
            {tabs.map((tab) => (
              <li
                key={tab.id}
                onClick={() => onTabChange(tab.id)}
                className={`flex items-center gap-4 p-2 cursor-pointer ${activeTab === tab.id
                  ? "bg-[#008847] rounded-[24px] font-normal text-white text-base"
                  : "text-black font-medium text-sm "
                  }`}
              >
                {tab.icon}
                {tab.name}
              </li>
            ))}
          </ul>
        </div>
        <div>
          <h1 className="font-medium text-[#808080] text-left text-sm">
            SETTINGS
          </h1>
          <div className="text-black font-medium space-y-2">
            <div
              onClick={() => setIsOpen(!isOpen)}
              className="flex items-center p-2 cursor-pointer">
              <Settings className="w-20" />
              <h1 className="text-sm">Account Settings</h1>
            </div>
            <div className="flex text-[#FD5823] items-center p-2 cursor-pointer">
              <LogOut className="w-20" />
              <h1 className="text-sm">Log Out</h1>
            </div>
          </div>
        </div>
      </aside>
      {
        isOpen && <AccountSettings />
      }
    </>
  );
};

export default Sidebar;
