import axios from "axios";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";

const QualificationExperience = () => {
  const { t } = useTranslation();

  const { id } = useParams();
  const [tutorData, setTutorData] = useState(null);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchTutorData = async () => {
      try {
        const res = await axios.get(
          `https://api.nadwa-uae.com/api/v2/tutor/${id}/`
        );
        setTutorData(res.data);
      } catch (error) {
        setError("Failed to fetch tutor data. Please try again later.");
      }
    };

    fetchTutorData();
  }, [id]);

  if (error) {
    return <p className="text-red-500">{error}</p>;
  }

  if (!tutorData) {
    return <p className="text-gray-500">Loading...</p>;
  }

  const { qualifications } = tutorData;

  return (
    <div className="px-10 py-10 space-y-8 hidden sm:block">
      {/* Academic Qualifications Section */}
      <div>
        <h2 className="text-2xl font-bold">{t('AcademicQualificaion')}</h2>
        <div className="overflow-x-auto">
          <table className="w-full border-collapse">
            <thead>
              <tr className="bg-gray-50">
                <th className="border border-gray-200 p-3 text-center font-medium">
                  S.No
                </th>
                <th className="border border-gray-200 p-3 text-left font-medium">
                  Degree
                </th>
                <th className="border border-gray-200 p-3 text-left font-medium">
                  Institute
                </th>
              </tr>
            </thead>
            <tbody>
              {qualifications &&
                qualifications.map((qual, index) => (
                  <tr key={index}>
                    <td className="border text-center border-gray-200 p-3">
                      {index + 1}
                    </td>
                    <td className="border border-gray-200 p-3">
                      {qual.qualification}
                    </td>
                    <td className="border border-gray-200 p-3">
                      {qual.institution}
                    </td>
                  </tr>
                ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
};

export default QualificationExperience;
