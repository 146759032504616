import React, { useState, useRef } from "react";
import { ChevronDown } from "lucide-react";

const ExperienceData = [
  { id: 1, name: "1 Year" },
  { id: 2, name: "2 Year" },
  { id: 3, name: "3 Year" },
  { id: 4, name: "4 Year" },
  { id: 5, name: "5 year" },
];

const Experience = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedExperience, setSelectedExperience] = useState(
    ExperienceData[0]
  );
  const dropdownRef = useRef(null);

  const handleExperienceSelect = (school: { id: number, name: string }) => {
    setSelectedExperience(school);
    setIsOpen(false);
  };

  return (
    <div className="space-y-8">
      <div>
        <h1 className="text-2xl text-[#272727] font-semibold">Experience</h1>
      </div>
      <div>
        <h1 className="text-[#272727] text-lg font-normal">
          How many years of relevant experience do you have?
        </h1>
      </div>

      <div className="relative w-full max-w-md" ref={dropdownRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full px-3 py-2 bg-white border border-gray-200 rounded-lg flex items-center justify-between "
        >
          <div className="flex items-center gap-2">
            <span className="text-base font-medium">
              {selectedExperience.name}
            </span>
          </div>
          <ChevronDown
            className={`w-5 h-5 text-gray-400 transition-transform ${isOpen ? "transform rotate-180" : ""
              }`}
          />
        </button>

        {isOpen && (
          <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg">
            <ul className="py-1 max-h-60 overflow-auto">
              {ExperienceData.map((experience) => (
                <li
                  key={experience.id}
                  onClick={() => handleExperienceSelect(experience)}
                  className="flex items-center gap-2 px-3 py-2 hover:bg-gray-50 cursor-pointer"
                >
                  <span className="text-sm font-medium">{experience.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>

      {/* TAGLINE */}
      <div>
        <h1 className="text-[#272727] text-lg font-normal">Tagline</h1>
        <input className="max-w-md w-full px-3 py-2 bg-white border border-gray-200 rounded-lg focus:outline-none text-base font-medium  " />
      </div>

      {/* DESCRIPTION */}
      <div>
        <h1 className="text-[#272727] text-lg font-normal">Description</h1>
        <textarea className="max-w-md w-full h-52 px-3 py-2 bg-white border border-gray-200 rounded-lg focus:outline-none  text-base font-medium " />
      </div>
    </div>
  );
};

export default Experience;
