import axios from "axios";
import React, { useEffect, useState } from "react";
import { useFilterContext } from "../../ContextApi/FilterContext";
import { useNavigate, } from "react-router-dom";
import { Search } from "lucide-react";
import { useTranslation } from "react-i18next";

const FormComponent = () => {
  const route = useNavigate()
   const { i18n }: any = useTranslation();
  const [subject, setSubject] = useState([]);
  const {
    setOtherFilters,
    selectSubject,
    setSelectSubject,
    setCountry,
    country,
    setSreach,
    sreach,
  } = useFilterContext();
  useEffect(() => {
    const tutorSubjects = async () => {
      try {
        const res2 = await axios.get('https://api.nadwa-uae.com/api/v3/subjects')
        setSubject(res2.data);
      } catch (error) {
        console.log(error)
      }
    }
    tutorSubjects()
  }, [])
  useEffect(() => {
    const getTutorFromCountry = async () => {
      try {
        const res = await axios.get('https://api.nadwa-uae.com/api/v2/tutor/', {
          params: {
            max_price: 400,
            min_price: 50,
            postcode: '',
            search: sreach,
            subject_id: selectSubject,
            level_id: '',
            country: country,
            online: true,
          },
        });
        setOtherFilters(res.data.results);
      } catch (error) {
        console.error('Error fetching tutors:', error);
      }
    };
    getTutorFromCountry();
  }, [country, selectSubject, setOtherFilters, setSelectSubject, setCountry, setSreach, sreach]);
  const getTutorFormSearching = async (e: any) => {
    e.preventDefault();
    try {
      const res = await axios.get(`https://api.nadwa-uae.com/api/v2/tutor/`, {
        params: {
          max_price: 400,
          min_price: 50,
          postcode: 'Online',
          search: sreach,
          subject_id: selectSubject,
          level_id: '',
          country: country,
          online: true,
        },
      });
      if (res.data.length !== 0) setOtherFilters(res.data.results);
      route('/tutor')
    } catch (error) {
      console.error('Error fetching tutors:', error);
    } finally {
      setSreach('')
    }
  };
  return (
    <div className="lg:w-[45%] w-full p-4 px-0">
      <form className="flex flex-col gap-4">
        {/* Row 1: Subject and Country Dropdowns */}
        <div className="flex gap-4 flex-col lg:flex-row items-center lg:items-start">
          <div className="flex-1 relative text-black text-base font-normal font-['Helvetica'] leading-none w-full">
            <select
              value={selectSubject}
              onChange={(e) => setSelectSubject(e.target.value)}
              className="border border-gray-300 rounded-xl h-8 lg:h-12 w-full px-2 focus:ring-2 focus:ring-green-400 appearance-none"
            >
              <option value="">All Subjects</option>
              {subject.map((ele: any) => (
                <optgroup key={ele.id} label={ele.name}>
                  {ele.subjects.map((sub: any) => (
                    <option key={sub.id} value={sub.id}>
                      {sub.name}
                    </option>
                  ))}
                </optgroup>
              ))}
            </select>
            <span
              className={`absolute top-1/2 ${i18n.language === 'ar' ? 'left-4' : 'right-4'} transform -translate-y-1/2 bg-[#00884780] lg:w-7 lg:h-7 w-5 h-5 rounded-full flex items-center justify-center pointer-events-none`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 h-3 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>
          <div className="flex-1 relative text-black w-full text-base font-normal font-['Helvetica'] leading-none">
            <select
              value={country}
              onChange={(e) => setCountry(e.target.value)}
              className="border border-gray-300 rounded-xl h-8 lg:h-12 w-full focus:ring-2 focus:ring-green-400 appearance-none px-2"
            >
              <option value="0">All Countries</option>
              <option value="1">United Kingdom</option>
              <option value="2">United Arab Emirates</option>
            </select>
            <span
              className={`absolute top-1/2 ${i18n.language === 'ar' ? 'left-4' : 'right-4'} transform -translate-y-1/2 bg-[#00884780] lg:w-7 lg:h-7 w-5 h-5 rounded-full flex items-center justify-center pointer-events-none`}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                className="w-3 h-3 text-white"
                viewBox="0 0 20 20"
                fill="currentColor"
              >
                <path
                  fillRule="evenodd"
                  d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z"
                  clipRule="evenodd"
                />
              </svg>
            </span>
          </div>
        </div>


        {/* Row 2: Search Bar */}
        <div className="flex gap-4 justify-center lg:justify-start">
          <div className={`flex items-center rounded-full focus-within:ring-2 focus-within:ring-green-500 relative w-full ${i18n.language === 'ar' ? 'flex-row-reverse' : ''}`}>
            <input
              value={sreach}
              onChange={(e) => setSreach(e.target.value)}
              type="text"
              className="flex-1 h-8 lg:h-12 p-3  focus:outline-none rounded-l-xl w-full placeholder:text-gray-600 placeholder:text-base placeholder:font-normal placeholder:font-['Helvetica'] placeholder:leading-none "
            />
             <Search className='absolute left-4 ' />
            <button
              onClick={(e) => getTutorFormSearching(e)}
              type="submit"
              className="h-8 lg:h-12 lg:px-8 px-3 text-sm bg-[#008847] text-white rounded-r-xl hover:bg-green-700 focus:outline-none text-right  lg:text-lg  font-bold font-['Helvetica']"
            >
              Search
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default FormComponent;
