import { useState } from "react"
import { X, Check, ChevronDown } from "lucide-react"

interface NotificationSetting {
  id: string
  label: string
  checked: boolean
}

export default function AccountSettings() {
  const [isOpen, setIsOpen] = useState(true)
  const [timeZone, setTimeZone] = useState("(GMT+04:00) Dubai")
  const [notifications, setNotifications] = useState<NotificationSetting[]>([
    { id: "new-message", label: "When I receive a new message", checked: true },
    { id: "cancel-lesson", label: "Customer cancels a lesson", checked: true },
    { id: "news-offers", label: "There's any offers or news from Nadwa", checked: true },
    { id: "sms-message", label: "Send me an SMS when I receive a new message", checked: true },
    { id: "new-enquiry", label: "When I receive a new enquiry", checked: true },
    { id: "lesson-status", label: "A customer accepts or rejects a lesson", checked: false },
    { id: "sms-enquiry", label: "Send me an SMS when I receive a new enquiry", checked: true },
    { id: "payment", label: "I am paid", checked: true },
  ])

  const toggleNotification = (id: string) => {
    setNotifications(
      notifications.map((notification) =>
        notification.id === id ? { ...notification, checked: !notification.checked } : notification,
      ),
    )
  }

  if (!isOpen) return null

  return (
    <div className="fixed inset-0 bg-black/70 z-30 flex items-center justify-center p-4">
      <div className="bg-white rounded-2xl max-w-[50%] w-full p-6">
        {/* Header */}
        <div className="flex items-center justify-between mb-6">
          <h2 className="text-xl font-semibold">Account Settings</h2>
          <button onClick={() => setIsOpen(false)} className="p-2 hover:bg-gray-100 rounded-full transition-colors">
            <X className="w-5 h-5" />
          </button>
        </div>

        {/* Time Zone Selector */}
        <div className="mb-6">
          <label className="block text-sm mb-2">Select your time zone</label>
          <div className="relative">
            <select
              value={timeZone}
              onChange={(e) => setTimeZone(e.target.value)}
              className="w-full p-3 pr-10 bg-white border rounded-lg appearance-none cursor-pointer"
            >
              <option value="(GMT+04:00) Dubai">(GMT+04:00) Dubai</option>
              <option value="(GMT+03:00) Kuwait">(GMT+03:00) Kuwait</option>
              <option value="(GMT+03:00) Riyadh">(GMT+03:00) Riyadh</option>
            </select>
            <ChevronDown className="w-5 h-5 absolute right-3 top-1/2 -translate-y-1/2 pointer-events-none text-gray-500" />
          </div>
        </div>

        {/* Notification Settings */}
        <div className="mb-6">
          <h3 className="text-base font-medium mb-4">Notification Settings</h3>
          <div className="space-y-4">
            {notifications.map((notification) => (
              <label key={notification.id} className="flex items-center gap-3 cursor-pointer group">
                <div
                  className={`w-5 h-5 rounded border flex items-center justify-center transition-colors
                    ${
                      notification.checked
                        ? "bg-[#008847] border-green-600"
                        : "border-gray-300 group-hover:border-green-600"
                    }`}
                  onClick={() => toggleNotification(notification.id)}
                >
                  {notification.checked && <Check className="w-3.5 h-3.5 text-white" />}
                </div>
                <span className="text-sm">{notification.label}</span>
              </label>
            ))}
          </div>
        </div>

        {/* Save Button */}
        <div className="flex justify-end">
          <button className="px-6 py-2 bg-[#008847] text-white rounded-xl hover:bg-green-700 transition-colors">
            Save Changes
          </button>
        </div>
      </div>
    </div>
  )
}

