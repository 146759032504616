import { GraduationCap } from "lucide-react";
import React from "react";

type TutorData = {
  photo: string; 
  user: {
    first_name: string; 
  };
};

type ProfileCardProps = {
  TutorData: TutorData;
};


const ProfileCard = ({ TutorData }: ProfileCardProps) => {
  const HighLightJobsData = [
    {
      id: 1,
      subject: "Maths Tutor Request",
      badge: "GCSE/GCSE",
      image: "https://via.placeholder.com/50", // Replace with the actual image URL
    },
    {
      id: 2,
      subject: "English Tutor Request",
      badge: "GCSE/GCSE",
      image: "https://via.placeholder.com/50", // Replace with the actual image URL
    },
    {
      id: 3,
      subject: "Physics Tutor Request",
      badge: "GCSE/GCSE",
      image: "https://via.placeholder.com/50", // Replace with the actual image URL
    },
  ];

  return (
    <div className="bg-white p-4 space-y-5 rounded-lg shadow">
      <div className="flex items-center">
        <h1 className="text-[#000606] text-2xl">My Profile</h1>
      </div>
      <div className="flex flex-col gap-4 justify-center items-center">
        <div className="rounded-full p-2 border w-40 h-40 flex items-center justify-center bg-white">
          <img
            src={TutorData.photo}
            alt={` profile`}
            className="w-32 h-32 rounded-full"
          />
        </div>
        <div className="ml-4">
          <h2 className="text-lg font-semibold">Good Morning {TutorData.user.first_name}</h2>
          {/* <div className="flex items-center justify-center gap-2"> */}
            {/* LIVE */}
            {/* <div className="bg-[#fee9e9] text-[#EE201C] w-12  p-1 rounded-2xl  flex items-center justify-center">
              <div className="text-green-500">{status}</div>
            </div> */}
            {/* RATE */}
            {/* <div className="bg-[#e6f4ed] text-[#008847] p-1 rounded-2xl flex items-center jus ">
              <div className="">{rate}</div>
            </div> */}
          {/* </div> */}
        </div>
      </div>
      {/* APPLY FOR JOBS */}
      <div>
        <h1 className="text-[#000606] text-xl ">Apply for Jobs</h1>
        <div className="bg-[#ebf6f1] p-2 rounded-lg shadow-md max-w-md mx-auto">
          {HighLightJobsData.map((job) => (
            <div
              key={job.id}
              className="flex flex-col 2xl:flex-row gap-2 items-center justify-between p-3 rounded-lg border-b border-dashed border-[#8dcaad]"
            >
              <div className="flex flex-col 2xl:flex-row items-center gap-3">
                <img
                  src={job.image}
                  alt={`${job.subject}`}
                  className="w-12 h-12 rounded"
                />
                <div>
                  <h3 className="font-medium text-[13px]">{job.subject}</h3>
                  <div className="flex items-center text-sm text-green-600 mt-1">
                    <div className="px-2 py-1 flex items-center bg-[#008847] text-white rounded">
                      <GraduationCap />
                      <span className="text-sm">{job.badge}</span>
                    </div>
                  </div>
                </div>
              </div>
              <button className="px-2 py-2 rounded-2xl text-[#008847] text-[12px] border-2 border-[#008847] ">
                View Job
              </button>
            </div>
          ))}
          <button className="bg-[#d4ece0]  text-green-600 w-full py-2 rounded-2xl mt-4 hover:bg-green-200">
            See All
          </button>
        </div>
      </div>
    </div>
  );
};

export default ProfileCard;
