import React from "react";

const EditProfileTutorSidebar = ({ activeTab, onTabChange }:{activeTab:string;onTabChange: (tab:any) => void}) => {
  const tabs = [
    { id: "basics", name: "Basics" },
    { id: "country", name: "Country" },
    { id: "school", name: "School" },
    { id: "experience", name: "Experience" },
    { id: "travel", name: "Travel Time" },
    { id: "qualifications", name: "Qualifications" },
    { id: "subjects", name: "Teaching Subjects" },
    { id: "pricing", name: "Pricing" },
    { id: "video", name: "Record a Video" },
    { id: "availability", name: "Availability" },
  ];
  return (
    <aside className=" bg-white w-fit border-r space-y-16 p-6">
      <div className="space-y-10">
        <ul className="space-y-3">
          {tabs.map((tab) => (
            <li
              key={tab.id}
              onClick={() => onTabChange(tab.id)}
              className={`flex items-center gap-4 p-2 font-medium cursor-pointer ${
                activeTab === tab.id
                  ? "bg-[#e6f4ed] text-[#008847] rounded-2xl w-fit text-[17px]  py-2 px-3"
                  : "text-[#898989] text-base font-medium "
              }`}
            >
              {tab.name}
            </li>
          ))}
        </ul>
      </div>
    </aside>
  );
};

export default EditProfileTutorSidebar;
