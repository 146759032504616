import BannerSection from '../components/AboutPages/Banner';
import CardSection from '../components/Blogs/Card-Section';
import bannerImage from '../images/Blogs/Hero.png';


function Blog() {
    return (
        <div>
            <BannerSection title='Blogs' image={bannerImage} />
            <CardSection/>
        </div>
    )
}

export default Blog;