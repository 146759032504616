import "swiper/css";
import "swiper/css/effect-cards";
import { Swiper, SwiperSlide } from "swiper/react";

// Import Swiper's effect-cards module
import { Autoplay, EffectCards } from "swiper/modules";

// Images
import client6 from "../../images/Tutor/7.png";
import client2 from "../../images/Tutor/1.png";
import client3 from "../../images/Tutor/3.png";
import client4 from "../../images/Tutor/4.png";
import client5 from "../../images/Tutor/5.png";
import colonimage from "../../images/Tutor/semicolon.png";

const CarouselSlider = () => {
  const slides = [
    {
      name: "Juan Cruz",
      grade: "12",
      review:
        "In a few months, I went from floundering in math to earning an A+! My teacher offered me useful advice on how to solve problems and very precisely described ideas. For my academics, Nadwa has been a godsaver.",
      imagePath: client3,
    },
    {
      name: " Catherine Martin",
      grade: "BS First Year",
      review:
        "My physics marks were hardly passing before I joined Nadwa. Now I graduated as one of the best student in my class thanks to my tutor's individualized direction. I never have felt so sure about my academics!",
      imagePath: client2,
    },
    {
      name: "Zhang Wei",
      grade: "7",
      review:
        "My perspective on chemistry has totally changed since I have started tutoring utilizing Nadwa . From a C to constant A's, this system has changed my life.",
      imagePath: client5,
    },
    {
      name: "Mariam Saleh",
      grade: "10",
      review:
        "Learning online with Nadwa has been considerably superior to my past in-person instruction. The sessions are interactive, adaptable, and so much more practical. With a tutor who really cares about my development, I can at last study at my own speed.",
      imagePath: client4,
    },
    {
      name: "Rami Jaber ",
      grade: "11",
      review:
        "The platform is great! It’s simple to use, the teachers are extremely skilled, and the number of disciplines provided is astounding. Nadwa  has helped me attain my objectives and feel more secure in my talents.",
      imagePath: client6,
    },
  ];

  return (
    <div className="w-full max-w-4xl mx-auto mt-4 ">
      <Swiper
        modules={[EffectCards, Autoplay]}
        effect="cards"
        grabCursor={true}
        autoplay={{ delay: 2000 }}
        loop={true}
        className="rounded-xl"
        dir="ltr" // Ensures cards are always displayed LTR
      >
        {slides.map((slide, i) => (
          <SwiperSlide key={i}>
            <div
              className={`h-auto sm:h-[350px] flex-shrink-0 bg-white rounded-2xl shadow-lg overflow-hidden`}
            >
              <div className="flex h-full flex-col sm:flex-row items-center">
                <div className="flex-1 p-6 sm:p-8 flex flex-col justify-center">
                  <img
                    src={colonimage}
                    alt="imag"
                    className="mb-4 sm:mb-6 w-[24px] sm:w-[74px] h-[24px] sm:h-[74px]"
                  />
                  <p className="text-[#12182D] text-sm sm:mr-24 sm:text-lg leading-[30px] mb-4 sm:mb-6">
                    {slide.review}
                  </p>
                  <div className="mt-4 sm:mt-auto">
                    <p className="font-semibold text-xl text-gray-800">
                      {slide.name}
                    </p>
                    <p className="text-[#ED1D24] font-semibold uppercase text-sm">
                      Grade <span className="text-[#550205]">{slide.grade}</span>
                    </p>
                  </div>
                </div>

                <div className="hidden sm:block w-80 h-80 rounded-full relative">
                  <img
                    src={slide.imagePath}
                    alt={`${slide.name}'s review`}
                    className="absolute inset-0 w-full h-full object-cover rounded-full p-6"
                  />
                </div>
              </div>
            </div>
          </SwiperSlide>
        ))}
      </Swiper>
    </div>
  );
};

export default CarouselSlider;
