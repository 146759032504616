import al from "../../images/Blogs/al.png";
import ar from "../../images/Blogs/ar.png";
import b1 from "../../images/Blogs/blog1.png";
import b2 from "../../images/Blogs/blog2.png";
import b3 from "../../images/Blogs/blog3.png";
import b4 from "../../images/Blogs/blog4.png";
import Card from './Card';
function BottomSec() {
    return (
        <div className='relative md:bottom-0 bottom-28 flex flex-col w-[90%] mx-auto py-20'>
            <div className='flex md:flex-row flex-col md:items-start items-center justify-between'>
                <div className="text-black text-3xl md:text-[55px] font-bold font-['Helvetica'] md:leading-[71.50px] mb-12 md:mb-5">You may also like</div>
                <div className='hidden md:flex gap-4 md:mt-0 mt-3 items-center'>
                    <div className='w-12 h-12 flex justify-center items-center bg-[#ED1D24] rounded-full'>
                        <img src={al} alt='none' className='object-contain' />
                    </div>
                    <div className='w-12 h-12 flex justify-center items-center bg-[#ED1D24] rounded-full'>
                        <img src={ar} alt='none' className='object-contain' />
                    </div>
                </div>
            </div>
            <div className="flex justify-center flex-wrap gap-4 w-[90%] mx-auto sm:w-full">
                {/* Always visible card */}
                <div>
                    <Card id={6} title={"Modern Physics with AI: Revolutionize Your Physics Learning"} image={b4} />
                </div>

                {/* Hidden on small screens, visible on medium and larger */}
                <div className="hidden sm:block">
                    <Card id={5} title={"Mastering comparative adjectives: A simple guide to making better comparisons"} image={b1} />
                </div>

                {/* Hidden on large screens and larger, visible on medium */}
                <div className="hidden md:block">
                    <Card id={4} title={"Language study plan: Your guide to creating the perfect language learning schedule"} image={b2} />
                </div>

                {/* Visible only on medium screens and larger */}
                <div className="hidden lg:block">
                    <Card id={7} title={"6 Key Digital Literacy Tips For Online Tutors To Develop"} image={b3} />
                </div>
            </div>

            <div className='w-full flex justify-end mt-10'>
                <div className="md:w-[191px]">
                    <div className="md:w-[191px] md:h-[50px] py-2 px-4 md:px-0 md:py-0 bg-[#ed1d24] rounded-[45px] text-white md:text-lg flex justify-center items-center mt-4">View More Blogs</div>
                </div>
            </div>
        </div>
    )
}

export default BottomSec