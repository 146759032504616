import React from "react";
import Image1 from "../../images/asserts/Website-Assets/About Us/image4.webp";
import Image2 from "../../images/asserts/Website-Assets/About Us/image3.webp";
import { useTranslation } from "react-i18next";
const AboutMission = () => {
  const { t }: { t: (key: string) => string } = useTranslation();
  return (
    <section className="relative bg-[#008847] py-16 border-t-4 border-gray-300">
      <div className="max-w-[94%] mx-auto">
        <div className="flex flex-col gap-16 text-white">
          <div className="flex flex-col lg:flex-row justify-between md:items-center gap-6 md:gap-20">
            <h3 className="xl:text-5xl text-3xl ml-4 md:ml-0 md:w-[15%] uppercase">{t('vision')}</h3>
            <div className="lg:max-w-[60%]">
              <p className="xl:text-[22px] max-w-[90%] mx-auto ">
                {t('vision2')}
              </p>
            </div>
            <div className="lg:w-[25%]">
              <img
                src={Image1}
                alt="mission1"
                className="w-[800px] lg:w-full"
              />
            </div>
          </div>

          <div className="border-t border-white pt-8">
            <div className="flex flex-col lg:flex-row justify-between md:items-center gap-6 md:gap-20">
              <h3 className="xl:text-5xl text-3xl md:w-[15%] uppercase ml-4 md:ml-0">{t('vision3')}</h3>
              <div className="lg:max-w-[60%]">
                <p className="xl:text-[22px] max-w-[90%] mx-auto">
                  {t('vision4')}
                </p>
              </div>
              <div className="lg:w-[25%]">
                <img
                  src={Image2}
                  alt="mission1"
                  className="w-[800px] lg:w-full"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutMission;
