import React, { useState, useRef } from "react";
import { ChevronDown } from "lucide-react";

const countries = [
  { code: "AE", name: "United Arab Emirates", flag: "🇦🇪" },
  { code: "US", name: "United States", flag: "🇺🇸" },
  { code: "GB", name: "United Kingdom", flag: "🇬🇧" },
  { code: "CA", name: "Canada", flag: "🇨🇦" },
  { code: "AU", name: "Australia", flag: "🇦🇺" },
];

const CountrySelector = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedCountry, setSelectedCountry] = useState(countries[0]);
  const dropdownRef = useRef(null);

  const handleCountrySelect = (country: { code: string; name: string; flag: string; }) => {
    setSelectedCountry(country);
    setIsOpen(false);
  };

  return (
    <div className="space-y-8">
      <div>
        <h1 className="text-2xl text-[#272727] font-semibold">Country</h1>
      </div>
      <div className="relative w-full max-w-md" ref={dropdownRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full px-3 py-2 bg-white border border-gray-200 rounded-lg flex items-center justify-between  focus:outline-none transition-all"
        >
          <div className="flex items-center gap-2">
            <span className="text-xl w-6 h-6 flex items-center justify-center">
              {selectedCountry.flag}
            </span>
            <span className="text-base font-medium">
              {selectedCountry.name}
            </span>
          </div>
          <ChevronDown
            className={`w-5 h-5 text-gray-400 transition-transform ${isOpen ? "transform rotate-180" : ""
              }`}
          />
        </button>

        {isOpen && (
          <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg ">
            <ul className="py-1 max-h-60 overflow-auto">
              {countries.map((country) => (
                <li
                  key={country.code}
                  onClick={() => handleCountrySelect(country)}
                  className="flex items-center gap-2 px-3 py-2 hover:bg-gray-50 cursor-pointer"
                >
                  <span className="text-xl w-6 h-6 flex items-center justify-center">
                    {country.flag}
                  </span>
                  <span className="text-base font-medium ">{country.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default CountrySelector;
