import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import './i18n'; 

const rootElement = document.getElementById('root');
if (!rootElement) {
    throw new Error("Root element with id 'root' not found.");
}
const root = ReactDOM.createRoot(rootElement);
root.render(
    <App />
);

reportWebVitals(console.log);
