import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast, Bounce } from "react-toastify";
import { useFilterContext } from "../../ContextApi/FilterContext";

const TutorMatchPopup = ({ setComplete, setTutorMatch, selectSubject, selectLevel, min, max, availableDays }: any) => {
    const [selectedTags, setSelectedTags] = useState<string[]>([]);
    const [data, setData] = useState<any>({});
    const route = useNavigate();
    const { setOtherFilters, setLoading } = useFilterContext();
    const tags: string[] = [
        "Aiming for 'A' grade",
        "Exam help",
        "Dyslexia",
        "SEN",
        "Last-minute revision",
        "Essay writing skills",
        "Sibling support",
        "Coursework support",
        "Outgoing",
        "Rational",
        "Sensitive",
        "Efficient",
        "Emotional",
    ];

    const toggleTag = (tag: string) => {
        setSelectedTags((prev) =>
            prev.includes(tag) ? prev.filter((t) => t !== tag) : [...prev, tag]
        );
    };

    useEffect(() => {
        const getUser = async () => {
            try {
                const res = await axios.get("https://api.nadwa-uae.com/api/v2/customer/profile/", {
                    headers: { Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}` },
                });
                setData(res.data);
            } catch (error: any) {
                console.error("Failed to fetch user data:", error.message);
                toast.error("Failed to fetch user data. Please try again.", { theme: "light", transition: Bounce });
            }
        };
        getUser();
    }, []);

    const postJob = async () => {
        if (!data) {
            toast.error("User data is not available. Please reload the page.", {
                autoClose: 3000,
                theme: "light",
                transition: Bounce,
            });
            return;
        }

        try {
            await axios.post(
                "https://api.nadwa-uae.com/api/v3/customer/jobs/",
                {
                    client_name: data.first_name,
                    client_email: data.email,
                    client_phone_number: data.phone_number,
                    full_address: "Test",
                    subject: selectSubject,
                    tags: selectedTags,
                    student_personality: [],
                    student_availability: availableDays,
                    price_min: min,
                    price_max: max,
                    level: selectLevel,
                    description: "",
                    postcode: "",
                },
                { headers: { Authorization: `Token ${localStorage.getItem("TH_AUTH_TOKEN")}` } }
            );
            setComplete(true);
            setTutorMatch(false);
        } catch (error: any) {
            const errorDetails = error.response?.data?.non_field_errors?.join(", ")
            toast.error(errorDetails, {
                autoClose: 3000,
                theme: "light",
                transition: Bounce,
                onClose: () => route("/tutor"),
            });
            try {
                setLoading(true);
                const res = await axios.get(
                    `https://api.nadwa-uae.com/api/v2/tutor/?subject_id=${selectSubject}&levelId=${selectLevel}&max_price=${max}&min_price=${min}&max_experience=60&min_experience=0&postcode&online=true`
                );
                setOtherFilters(res.data.results);
            } catch (error) {
                console.log(error);
            } finally {
                setLoading(false);
            }
        }
    };

    const handleSubmit = () => {
        postJob();
    };
    return (
        <div className="fixed inset-0 bg-gray-800 bg-opacity-50 flex justify-center items-center">
            <ToastContainer
                position="top-right"
                autoClose={3000}
                hideProgressBar={false}
                closeOnClick={false}
                pauseOnHover
                draggable
                theme="light"
                transition={Bounce}
            />
            <div className="bg-white rounded-[20px] shadow-lg w-11/12 max-w-3xl p-6">
                <div className="relative w-full my-6">
                    <div className="h-2 bg-[#00884733] w-full" />
                    <div className="h-2 bg-green-600 w-[90%] absolute top-0 left-0">
                        <div className="absolute -top-5 -right-5">
                            <div className="bg-green-600 text-[10px] text-white px-2 py-0.5 rounded-full uppercase font-medium">
                                90%
                            </div>
                        </div>
                    </div>
                </div>
                <h2 className="text-xl font-semibold text-gray-800 mb-2">Tailor your tutor match</h2>
                <p className="text-black text-sm mb-6 w-[70%]">
                    Ok last Step - We want to make sure we match you perfectly with your tutor before your{" "}
                    <span className="font-semibold">Free Trial Lesson</span> – so let us know a bit more about your
                    son/daughter (or yourself if the tutoring is for you) by selecting at least{" "}
                    <span className="font-semibold">2 tags</span> from the below.
                </p>
                <div className="flex flex-wrap gap-3 mb-6">
                    {tags.map((tag) => (
                        <button
                            key={tag}
                            className={`px-4 py-2 rounded-xl text-sm font-medium ${selectedTags.includes(tag)
                                ? "bg-[#008847] text-white"
                                : "bg-[#0088471A] border-2 border-[#008847] text-gray-700"
                                } hover:bg-[#008847] hover:text-white transition`}
                            onClick={() => toggleTag(tag)}
                        >
                            {tag}
                        </button>
                    ))}
                </div>
                <button
                    disabled={selectedTags.length < 2}
                    className={`w-[233px] bg-[#008847] text-white py-2 text-xl rounded-xl font-medium ${selectedTags.length < 2 ? "bg-gray-300 cursor-not-allowed" : ""
                        } `}
                    onClick={handleSubmit}
                >
                    Submit
                </button>
            </div>
        </div>
    );
};

export default TutorMatchPopup;
