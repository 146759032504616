import React, { useState } from "react";
import { Eye, EyeOff } from "lucide-react";
import LogoImage from "../../images/asserts/Dashboard Assets/Logo.webp";
import img2 from "../../images/asserts/Dashboard Assets/image1-min.webp";
import { Link, useNavigate } from "react-router-dom";

const LoginStudentForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [credentials, setCredentials] = useState({
    email: "",
    password: "",
    telephone: "",
  });
  const [loginMethod, setLoginMethod] = useState("email");
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setCredentials({
      ...credentials,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();

    // Prepare payload based on login method
    const payload =
      loginMethod === "email"
        ? {
            method: "email",
            password: credentials.password,
            phoneLoginMethod: "password",
            sms_verification_code: "",
            username: credentials.email,
          }
        : {
            method: "phone",
            password: credentials.password,
            phoneLoginMethod: "password",
            sms_verification_code: "",
            username: credentials.telephone,
          };

    try {
      setIsLoading(true);
      const response = await fetch(
        // Login Api
        "https://api.nadwa-uae.com/api/v2/users/login_new/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          // Data
          body: JSON.stringify(payload),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Login successful:", data);
        alert("Login successful!");
        navigate("/dashboard");
      } else {
        const errorData = await response.json();
        console.error("Login error:", errorData);
        alert(errorData.message || "Login failed. Please try again.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("An error occurred. Please try again later.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen gap-1 py-20 xl:py-0 xl:gap-20 bg-white">
      {/* LEFT */}
      <div className="space-y-5 px-4">
        <div className="xl:w-44 2xl:w-52 h-20">
          <img src={LogoImage} className="w-52" alt="Logo" />
        </div>
        <div className="flex flex-col space-y-5 px-10">
          <div className="space-y-2">
            <h1 className="text-4xl font-normal">
              Hey, Hello{" "}
              <span role="img" aria-label="wave">
                👋
              </span>
            </h1>
            <p className="text-[#393939] font-normal text-lg leading-6">
              Enter your credentials to log in to your Account
            </p>
          </div>

          <div className="flex justify-center items-center w-[230px] h-[50px] rounded-[30px] bg-[#e8f4ea]">
            <button
              onClick={() => setLoginMethod("email")}
              className={`py-2 px-6 ${
                loginMethod === "email"
                  ? "bg-[#008847] text-white h-10 rounded-[33px]"
                  : "bg-transparent text-[#4D4D4D]"
              }`}
            >
              Email
            </button>
            <button
              onClick={() => setLoginMethod("telephone")}
              className={`py-2 px-6 ${
                loginMethod === "telephone"
                  ? "bg-[#008847] text-white h-10 rounded-[33px]"
                  : "bg-transparent text-[#4D4D4D]"
              }`}
            >
              Telephone
            </button>
          </div>

          <form onSubmit={handleSubmit} className="w-full max-w-md space-y-4">
            {loginMethod === "email" && (
              <div className="space-y-3">
                <label className="block font-bold text-base text-black">
                  Email
                </label>
                <input
                  type="email"
                  name="email"
                  value={credentials.email}
                  onChange={handleChange}
                  placeholder="Enter your email"
                  className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] text-[#667085] font-normal text-base placeholder-[#667085] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                />
              </div>
            )}
            {loginMethod === "telephone" && (
              <div className="space-y-3">
                <label className="block font-bold text-base text-black">
                  Telephone
                </label>
                <div className="group w-full flex items-center border border-[#D2D2D2] rounded-[40px] text-[#101828] font-normal text-base placeholder-[#667085] focus-within:ring-2 focus-within:ring-[#008847]">
                  <div className="flex items-center">
                    <select
                      name="countryCode"
                      className="bg-transparent px-4 outline-none font-normal"
                    >
                      <option value="+971">UAE</option>
                      <option value="+1">US</option>
                      <option value="+91">India</option>
                    </select>
                  </div>
                  <input
                    type="tel"
                    name="telephone"
                    value={credentials.telephone}
                    onChange={handleChange}
                    placeholder="+971 (555) 000-0000"
                    className="flex-1 px-4 py-2 font-normal text-[#101828] placeholder-[#101828] text-base focus:outline-none"
                  />
                </div>
              </div>
            )}

            {/* Password Field */}
            <div className="space-y-3">
              <label className="block font-bold text-base text-black">
                {loginMethod === "email" ? "Password" : "Password or OTP"}
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  name="password"
                  value={credentials.password}
                  onChange={handleChange}
                  placeholder="Enter your password"
                  className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] text-[#667085] font-normal text-base placeholder-[#667085] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-4 flex items-center text-[#667085]"
                >
                  {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>
            </div>

            <div className="flex justify-end items-center">
              <Link
                to="/forgot-password"
                className="text-sm text-[#008847] font-semibold hover:underline"
              >
                Forgot Password?
              </Link>
            </div>

            <button
              type="submit"
              className="px-6 py-2 w-full bg-[#008847] text-white font-medium text-xl rounded-[28px] border-[5px] border-[#66b891]"
              disabled={isLoading}
            >
              {isLoading ? "Loading..." : "Login"}
            </button>
          </form>

          <div className="pt-10">
            <div className="flex xl:flex-row flex-col items-center xl:space-x-8 xl:space-y-0 space-y-4">
              <Link
                to="/SignupTutor"
                className="border border-[#008847] w-full xl:w-[250px] text-center xl:text-left no-underline bg-[#e5f3ed] rounded-[40px] text-[#008847] text-base font-semibold py-3 px-10"
              >
                Sign Up as Tutor
              </Link>
              <Link
                to="/SignupStudent"
                className="border border-[#008847] w-full xl:w-[250px] text-center xl:text-left no-underline bg-[#e5f3ed] rounded-[40px] text-[#008847] text-base font-semibold py-3 px-10"
              >
                Sign Up as Student
              </Link>
            </div>
          </div>
        </div>
      </div>

      {/* RIGHT */}
      <div className="w-[400px] lg:w-[450px] xl:w-[500px] 2xl:w-[600px] h-[500px] lg:h-[580px] xl:h-[600px] 2xl:h-[650px]">
        <img src={img2} alt="Right Section" className="w-full" />
      </div>
    </div>
  );
};

export default LoginStudentForm;
