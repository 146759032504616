import React, { useRef, useState } from "react";
import LogoImage from "../../images/asserts/Dashboard Assets/Logo.webp";
import img2 from "../../images/asserts/Dashboard Assets/image1-min.webp";
import { Eye, EyeOff } from "lucide-react";
import { Link, useNavigate } from "react-router-dom";

const SignupStudentForm = () => {
  const [showPassword, setShowPassword] = useState(false);
  const [showGetOtp, setShowGetOtp] = useState(false);
  const [otp, setOtp] = useState(""); // State for OTP input
  const getFirstName = useRef<HTMLInputElement>(null);
  const getLastName = useRef<HTMLInputElement>(null);
  const getEmail = useRef<HTMLInputElement>(null);
  const getPhone = useRef<HTMLInputElement>(null);
  const getPassword = useRef<HTMLInputElement>(null);
  const navigate = useNavigate();

  // Handle Phone Number
  const handlePhoneChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const phoneValue = e.target.value;
    // Agar phone number ki length 7 se zada hogi jab otp ki feild show hogi
    if (phoneValue.length > 7) {
      setShowGetOtp(true); // Show "Get OTP" field
    } else {
      setShowGetOtp(false); // Hide "Get OTP" field
    }
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      !getFirstName.current?.value ||
      !getLastName.current?.value ||
      !getEmail.current?.value ||
      !getPhone.current?.value ||
      !getPassword.current?.value ||
      !otp
    ) {
      return alert("Please enter all details, including OTP.");
    }

    try {
      const response = await fetch(
        "https://api.nadwa-uae.com/api/v3/customer/registersms/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            first_name: getFirstName.current.value,
            last_name: getLastName.current.value,
            email: getEmail.current.value,
            phone_number: getPhone.current.value,
            password: getPassword.current.value,
            sms_verification_code: otp,
            referer: "https://nadwa-uae.com/student/register", // Optional
          }),
        }
      );

      if (response.ok) {
        const data = await response.json();
        console.log("Response data:", data);
        navigate("/LoginStudent");
      } else {
        const errorData = await response.json();
        alert(errorData.message || "Something went wrong.");
      }
    } catch (error) {
      console.error("Error:", error);
      alert("Failed to create an account. Please try again later.");
    }
  };

  const handleOtp = async () => {
    try {
      const response = await fetch(
        // Sign Up Student
        "https://api.nadwa-uae.com/api/v2/users/sms_verification_code/",
        {
          method: "POST",
          headers: {
            "Content-Type": "application/json",
          },
          body: JSON.stringify({
            phone: getPhone.current?.value,
            recaptcha_token: process.env.REACT_APP_RECAPCHA_TOKEN,
          }),
        }
      );

      if (response.ok) {
        const res = await response.json();
        console.log("OTP sent:", res);
        alert("OTP has been sent to your phone.");
      } else {
        alert("Failed to send OTP. Please try again.");
      }
    } catch (error) {
      console.error("Error sending OTP:", error);
      alert("Error sending OTP. Please try again.");
    }
  };

  return (
    <div className="flex justify-center items-center h-screen gap-10 xl:gap-20 py-20 xl:py-0 bg-white">
      {/* Left Section */}
      <div className="space-y-5 px-4">
        <div className="w-52 h-20">
          <img src={LogoImage} className="w-52" alt="Logo" />
        </div>
        <div className="flex flex-col space-y-5 px-10 bg-white">
          <div className="space-y-2">
            <h1 className="text-2xl font-semibold">Create Student Account</h1>
            <p className="text-[#393939] font-normal text-lg leading-6">
              Already have an account?{" "}
              <Link
                to={"/LoginStudent"}
                className="text-[#008847] font-medium underline"
              >
                Log in
              </Link>
            </p>
          </div>

          {/* Form */}
          <form onSubmit={handleSubmit} className="space-y-4">
            <div className="flex space-x-4">
              {/* First Name */}
              <div className="space-y-3">
                <label className="block font-bold text-base text-black">
                  First Name
                </label>
                <input
                  type="text"
                  ref={getFirstName}
                  className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                  placeholder="Enter First Name"
                />
              </div>
              {/* Last Name */}
              <div className="space-y-3">
                <label className="block font-bold text-base text-black">
                  Last Name
                </label>
                <input
                  type="text"
                  ref={getLastName}
                  className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                  placeholder="Enter Last Name"
                />
              </div>
            </div>

            {/* Email */}
            <div className="space-y-3">
              <label className="block font-bold text-base text-black">
                Email
              </label>
              <input
                type="email"
                ref={getEmail}
                className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                placeholder="Enter Your Email"
              />
            </div>

            {/* Telephone */}
            <div className="space-y-3 rounded-[40px]">
              <label className="block font-bold text-base text-black">
                Telephone
              </label>
              <div className="flex items-center w-full border border-[#D2D2D2] rounded-[40px] focus-within:ring-2 focus-within:ring-[#008847]">
                <select
                  className="px-5 py-2 outline-none rounded-l-lg rounded-[40px]"
                  defaultValue="+971"
                >
                  <option value="+971">UAE</option>
                  <option value="+1">US</option>
                  <option value="+44">UK</option>
                </select>
                <input
                  type="tel"
                  ref={getPhone}
                  onChange={handlePhoneChange}
                  className="w-full px-4 py-2 rounded-r-lg focus:outline-none rounded-[40px]"
                  placeholder="Enter Your Number"
                />
              </div>
              {showGetOtp && (
                <div className="relative space-y-3">
                  <label className="block font-bold text-base text-black">
                    OTP
                  </label>
                  <input
                    type="text"
                    value={otp}
                    onChange={(e) => setOtp(e.target.value)}
                    className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                    placeholder="Enter Your OTP"
                  />
                  <button
                    type="button"
                    onClick={handleOtp}
                    className="absolute right-0 bottom-0 mt-2 px-6 py-2 bg-[#008847] text-white font-medium rounded-[28px]"
                  >
                    Get OTP
                  </button>
                </div>
              )}
            </div>

            {/* Password */}
            <div className="space-y-3">
              <label className="block font-bold text-base text-black">
                Password
              </label>
              <div className="relative">
                <input
                  type={showPassword ? "text" : "password"}
                  ref={getPassword}
                  className="w-full px-4 py-2 border border-[#D2D2D2] rounded-[40px] focus:outline-none focus:ring-2 focus:ring-[#008847]"
                  placeholder="Enter your password"
                />
                <button
                  type="button"
                  onClick={() => setShowPassword(!showPassword)}
                  className="absolute inset-y-0 right-4 flex items-center text-[#667085]"
                >
                  {showPassword ? <EyeOff size={20} /> : <Eye size={20} />}
                </button>
              </div>
            </div>

            {/* Submit Button */}
            <button
              type="submit"
              className="w-full py-3 bg-[#008847] text-white font-bold rounded-[40px] hover:bg-[#006836]"
            >
              Register
            </button>
          </form>
        </div>
      </div>

      {/* Right Section */}
      <div className="hidden lg:flex items-center">
        <img src={img2} className="h-[600px]" alt="Student Registration" />
      </div>
    </div>
  );
};

export default SignupStudentForm;
