import React from "react";
import { BrowserRouter as Router, Routes, Route, useLocation } from "react-router-dom";
import Footer from "./components/Footer";
import Blog from "./screens/Blog";
import ContactForm from "./screens/ContactUs";
import DashboardStudent from "./screens/DashboardStudent";
import DashboardTutor from "./screens/DashboardTutor"
import EditProfileTutor from "./screens/EditProfileTutor";
import Home from "./screens/Home";
import HowItWork from "./screens/HowItWork";
import LoginTutor from "./screens/LoginTutor";
import SignupStudent from "./screens/SignupStudent";
import SignupTutor from "./screens/SignupTutor";
import SingleBlog from "./screens/SingleBlog";
import Tutor from "./screens/Tutor";
import PrivacyPolices from "./screens/PrivacyPolices";
import Terms from "./screens/Terms";
import NavbarComponent from "./components/NavbarComponent";
import { FilterProvider } from "./ContextApi/FilterContext";
import LoginStudent from "./screens/LoginStudent";
import About from "./screens/About";
import AboutTutor from "./screens/AboutTutor";
import ErrorPage from "./screens/ErrorPage";

function App() {
  return (
    <FilterProvider>
      <div className="overflow-hidden ">
        <Router future={{ v7_startTransition: true }}>
          <Layout>
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/Aboutus" element={<About />} />
              <Route path="/Tutor/:id" element={<AboutTutor />} />
              <Route path="/LoginTutor" element={<LoginTutor />} />
              <Route path="/About" element={<About />} />
              <Route path="/LoginStudent" element={<LoginStudent />} />
              <Route path="/SignupTutor" element={<SignupTutor />} />
              <Route path="/SignupStudent" element={<SignupStudent />} />
              <Route path="/Dashboard" element={<DashboardTutor />} />
              <Route path="/DashboardStudent" element={<DashboardStudent />} />
              <Route path="/EditProfile" element={<EditProfileTutor />} />
              <Route path="/ContactUs" element={<ContactForm />} />
              <Route path="/HowItWork" element={<HowItWork />} />
              <Route path="/Blogs" element={<Blog />} />
              <Route path="/Blogs/:id" element={<SingleBlog />} />
              <Route path="/Tutor" element={<Tutor />} />
              <Route path="/Term-conditions" element={<Terms />} />
              <Route path="/Privacy-policy" element={<PrivacyPolices />} />
              <Route path="*" element={<ErrorPage />} />
            </Routes>
          </Layout>
          <Footer />
        </Router>
      </div>
    </FilterProvider>
  );
}

// Layout component
const Layout = ({ children }: any) => {
  const location = useLocation();
  const noNavbarRoutes = ["/Dashboard", "/SignupTutor", "/LoginTutor", "/SignupStudent", "/EditProfile", "/DashboardStudent"];
  const showNavbar = !noNavbarRoutes.includes(location.pathname);
  return (
    <>
      {showNavbar && <NavbarComponent />}
      {children}
    </>
  );
};

export default App;
