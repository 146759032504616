import BannerSection from '../components/AboutPages/Banner'
import React from 'react'
import bannerImage from '../images/asserts/Website-Assets/Privacy Policy/Hero_image1.webp'
import Section2 from '../components/PrivacyPages/Section2'
const PrivacyPolices = () => {
    return (
        <main>
            <BannerSection title='Privacy Policy' image={bannerImage} />
            <Section2 />
        </main>
    )
}

export default PrivacyPolices