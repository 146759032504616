import { useTranslation } from "react-i18next";
import BannerSection from "../components/AboutPages/Banner";
// import AcademicsSubject from "../components/AboutTutorComponents/Current-Academics&Subjects";
import QualificationExperience from "../components/AboutTutorComponents/QualificationExperience";
import ReviewsCarousel from "../components/AboutTutorComponents/ReviewsCarousel";
import TutorIntroduction from "../components/AboutTutorComponents/TutorIntroduction";
import TutorTimingCalender from "../components/AboutTutorComponents/TutorTimingCalender";
import TutorVideo from "../components/AboutTutorComponents/TutorVideo";
import bannerImage from "../images/AboutImages/colleagues-while-working-process1.png";

function AboutTutor() {
  const { t }: { t: (key: string) => string } = useTranslation();

  return (
    <div className="">
      <BannerSection title={t("AboutTutor")} image={bannerImage} />
      <TutorIntroduction />
      <TutorVideo />
      {/* <AcademicsSubject /> */}
      <QualificationExperience />
      <TutorTimingCalender />
      <ReviewsCarousel />
    </div>
  );
}

export default AboutTutor;
