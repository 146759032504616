import React, { useState } from "react";

const QualificationsManager = () => {
  const [qualifications, setQualifications] = useState([
    { id: 1, institution: "", qualification: "" },
  ]);
  const [focusedField, setFocusedField] = useState({ id: null, field: null });

  const handleAddQualification = () => {
    setQualifications([
      ...qualifications,
      {
        id: qualifications.length + 1,
        institution: "",
        qualification: "",
      },
    ]);
  };

  const handleRemoveField = (id: number, field: string) => {
    setQualifications(
      qualifications.map((qual) =>
        qual.id === id ? { ...qual, [field]: "" } : qual
      )
    );
    setFocusedField({ id: null, field: null });
  };

  const handleInputChange = (id: number, field: string, value: string) => {
    setQualifications(
      qualifications.map((qual) =>
        qual.id === id ? { ...qual, [field]: value } : qual
      )
    );
  };

  const handleFocus = (id: number, field: string) => {
    setFocusedField({ id: null, field: null });
  };

  return (
    <div className="max-w-2xl">
      <div className="flex justify-between items-center mb-6">
        <h2 className="text-2xl text-[#272727] font-semibold">
          Qualifications
        </h2>
        <button
          onClick={handleAddQualification}
          className="flex items-center gap-2 bg-[#008847] text-white px-4 py-2 hover:bg-green-700 rounded-xl"
        >
          <span className="text-xl">+</span>
          Add Qualification
        </button>
      </div>

      <div className="space-y-4">
        {qualifications.map((qual) => (
          <div key={qual.id} className="grid grid-cols-2 gap-4">
            <div className="relative space-y-3">
              <label className="block text-base font-normal text-[#272727] mb-1">
                Institution
              </label>
              <input
                type="text"
                value={qual.institution}
                onChange={(e) =>
                  handleInputChange(qual.id, "institution", e.target.value)
                }
                onFocus={() => handleFocus(qual.id, "institution")}
                className="w-full p-2 text-base font-medium border border-gray-300 rounded-md focus:outline-none"
                placeholder="Enter institution name"
              />
              {focusedField.id === qual.id &&
                focusedField.field === "institution" && (
                  <div className="absolute  bg-[#f6d7d6] border-2 border-[#EE201C] rounded-xl w-full flex justify-end mt-0 p-3">
                    <button
                      onClick={() => handleRemoveField(qual.id, "institution")}
                      className="text-[#EE201C] text-base font-medium"
                    >
                      Remove
                    </button>
                  </div>
                )}
            </div>

            <div className="relative space-y-3">
              <label className="block text-base font-normal text-[#272727] mb-1">
                Qualification & Subject
              </label>
              <input
                type="text"
                value={qual.qualification}
                onChange={(e) =>
                  handleInputChange(qual.id, "qualification", e.target.value)
                }
                onFocus={() => handleFocus(qual.id, "qualification")}
                className="w-full p-2 text-base font-medium border border-gray-300 rounded-md focus:outline-none"
                placeholder="Enter qualification"
              />
              {focusedField.id === qual.id &&
                focusedField.field === "qualification" && (
                  <div className="absolute  bg-[#f6d7d6] border-2 border-[#EE201C] rounded-xl w-full flex justify-end mt-0 p-3">
                    <button
                      onClick={() =>
                        handleRemoveField(qual.id, "qualification")
                      }
                      className="text-[#EE201C] text-base font-medium"
                    >
                      Remove
                    </button>
                  </div>
                )}
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default QualificationsManager;
