import { BadgeAlert } from "lucide-react";
import React, { useState } from "react";

const TravelTime = () => {
  const [selectedTime, setSelectedTime] = useState("");
  const [postcode, setPostcode] = useState("");

  const timeOptions = [
    { label: "Online Only", value: "online" },
    { label: "30 Minutes", value: "30" },
    { label: "45 Minutes", value: "45" },
    { label: "60 Minutes", value: "60" },
    { label: "90 Minutes", value: "90" },
  ];

  const handleTimeSelect = (value: string) => {
    setSelectedTime(value);
  };

  const handlePostcodeChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setPostcode(e.target.value);
  };

  return (
    <div className="max-w-md  space-y-6">
      <h1 className="text-2xl text-[#272727] font-semibold ">Travel Time</h1>

      <div className="space-y-5">
        <div className="flex items-center gap-2 text-sm font-medium text-gray-700">
          Enter Your Postcode
          <BadgeAlert className="text-[#008847]" />
        </div>
        <input
          type="text"
          value={postcode}
          onChange={handlePostcodeChange}
          className="w-full p-2 text-base text-[#272727] border border-gray-300 rounded-md focus:outline-none "
          placeholder="Enter postcode"
        />
      </div>

      <div className="space-y-2">
        <p className="text-sm font-medium text-gray-700">
          How long would you be happy to travel to do a face-to-face lesson?
        </p>
        <div className="flex flex-wrap gap-2">
          {timeOptions.map((option) => (
            <button
              key={option.value}
              onClick={() => handleTimeSelect(option.value)}
              className={`px-4 py-2 rounded-md transition-colors ${selectedTime === option.value
                  ? "bg-[#008847] text-white"
                  : "bg-green-100 text-[#008847] hover:bg-green-200"
                }`}
            >
              {option.label}
            </button>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TravelTime;
