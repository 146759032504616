import { useState, useEffect } from "react";
import { Sun, Moon } from "lucide-react";

interface TimeSlot {
    id: string;
    time: string; 
    selected: boolean;
}

interface DaySchedule {
    name: string;
    slots: TimeSlot[];
}

export default function AvailabilitySelector({ setTutorMatch, setShowDays, setAvailableDays, availableDays }: any) {
    const [isButtonDisabled, setIsButtonDisabled] = useState(true);

    const initialSchedule: DaySchedule[] = [
        "Monday",
        "Tuesday",
        "Wednesday",
        "Thursday",
        "Friday",
        "Saturday",
        "Sunday",
    ].map((day, index) => ({
        name: day,
        slots: [
            { id: `${day}-1`, time: "1", selected: false }, // Morning
            { id: `${day}-2`, time: "2", selected: false }, // Afternoon
            { id: `${day}-3`, time: "3", selected: false }, // Evening
        ],
    }));

    const [schedule, setSchedule] = useState(initialSchedule);

    // Effect to update the button's disabled state based on availableDays
    useEffect(() => {
        setIsButtonDisabled(availableDays.length === 0);
    }, [availableDays]);

    const toggleSlot = (dayIndex: number, slotIndex: number) => {
        setSchedule((prev) => {
            const newSchedule = [...prev];
            newSchedule[dayIndex] = {
                ...newSchedule[dayIndex],
                slots: newSchedule[dayIndex].slots.map((slot, idx) =>
                    idx === slotIndex ? { ...slot, selected: !slot.selected } : slot
                ),
            };

            // Update availableDays state
            const selectedDay = newSchedule[dayIndex];
            const selectedSlot = selectedDay.slots[slotIndex];
            setAvailableDays((prevAvailableDays: any) => {
                if (!selectedSlot.selected) {
                    return prevAvailableDays.filter(
                        (entry: any) => !(entry.slot === selectedSlot.time && entry.days.includes(dayIndex + 1))
                    );
                }

                const updatedDays = [...prevAvailableDays];
                const existingEntry = updatedDays.find((entry) => entry.slot === selectedSlot.time);

                if (existingEntry) {
                    existingEntry.days.push(dayIndex + 1); // Add day starting from 1
                } else {
                    updatedDays.push({ slot: selectedSlot.time, days: [dayIndex + 1] });
                }

                return updatedDays;
            });

            return newSchedule;
        });
    };

    return (
        <div className="fixed inset-0 bg-black/70 bg-opacity-50 flex justify-center items-center">
            <div className="max-w-[50%] w-full">
                <div className="bg-white rounded-[20px] overflow-hidden p-6">
                    {/* Header */}
                    <div className="relative w-full my-6">
                        <div className="h-2 bg-[#00884733] w-full" />
                        <div className="h-2 bg-green-600 w-[70%] absolute top-0 left-0">
                            <div className="absolute -top-5 -right-5">
                                <div className="bg-green-600 text-[10px] text-white px-2 py-0.5 rounded-full uppercase font-medium">70%</div>
                            </div>
                        </div>
                    </div>
                    {/* Content */}
                    <div>
                        <h2 className="text-lg font-medium mb-2">When are you available?</h2>
                        <p className="text-sm text-gray-600 mb-6">
                            When are you available for your Free Trial Lesson? Our tutors will work around your busy schedule, but we
                            recommend selecting at least 5 options from the below.
                        </p>

                        {/* Calendar Grid */}
                        <div className="col-span-7 bg-[#008847] flex justify-between px-3 rounded-[20px] mb-4">
                            {schedule.map((day) => (
                                <div key={day.name} className="text-center bg-[#008847]">
                                    <div className=" text-white py-2 px-1 rounded">{day.name}</div>
                                </div>
                            ))}
                        </div>
                        <div className="flex justify-end items-end gap-2 mb-4">
                            <button
                                onClick={() => {
                                    setSchedule((prev) =>
                                        prev.map((day) => ({
                                            ...day,
                                            slots: day.slots.map((slot) => ({ ...slot, selected: true })),
                                        }))
                                    );
                                    setAvailableDays([
                                        { slot: 1, days: Array.from({ length: 7 }, (_, i) => i + 1) },
                                        { slot: 2, days: Array.from({ length: 7 }, (_, i) => i + 1) },
                                        { slot: 3, days: Array.from({ length: 7 }, (_, i) => i + 1) },
                                    ]);
                                }}
                                className="px-2 py-1 bg-[#0088471A] border border-[#008847] text-[#008847] rounded-[7px] text-sm font-bold transition-colors"
                            >
                                Select All
                            </button>
                            <button
                                onClick={() => {
                                    setSchedule((prev) =>
                                        prev.map((day) => ({
                                            ...day,
                                            slots: day.slots.map((slot) => ({ ...slot, selected: false })),
                                        }))
                                    );
                                    setAvailableDays([]);
                                }}
                                className="px-2 py-1 bg-[#ED1D241A] text-[#ED1D24] rounded-[7px] border border-[#ED1D24] transition-colors text-sm font-bold"
                            >
                                Clear
                            </button>
                        </div>
                        <div className="grid grid-cols-7 gap-3 mb-6 relative">
                            {schedule.map((day, dayIndex) =>
                                day.slots.map((slot, slotIndex) => (
                                    <div key={`${day.name}-${slot.time}`} className="text-center">
                                        <button
                                            onClick={() => toggleSlot(dayIndex, slotIndex)}
                                            className={`w-full p-2 rounded border transition-colors ${slot.selected
                                                ? "bg-[#008847] text-white"
                                                : "bg-[#0088471A] border border-[#008847]"
                                                }`}
                                        >
                                            {slot.time === "1" || slot.time === "2" ? (
                                                <Sun className="w-4 h-4 mx-auto mb-1" />
                                            ) : (
                                                <Moon className="w-4 h-4 mx-auto mb-1" />
                                            )}
                                            <span className="text-xs">{`Slot ${slot.time}`}</span>
                                        </button>
                                    </div>
                                ))
                            )}
                        </div>

                        {/* Action Button */}
                        <button
                            disabled={isButtonDisabled}
                            className={`w-1/3 py-2 text-white rounded-xl transition-colors ${isButtonDisabled ? "bg-gray-300 cursor-not-allowed" : "bg-[#008847]"
                                }`}
                            onClick={() => {
                                setTutorMatch(true);
                                setShowDays(false);
                            }}
                        >
                            Next
                        </button>
                    </div>
                </div>
            </div>
        </div>
    );
}
