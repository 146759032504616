import { Bell } from "lucide-react";

export default function TutoringPermit() {
  return (
    <div className="bg-gray-100 min-h-screen ">
      <div className="">
        {/* Header */}
        <div className="flex justify-between items-center">
          <h1 className="text-[#000606] font-normal text-3xl">
            Apply to the UAE private tutoring licence
          </h1>
          <div className="flex items-center gap-3">
            <div className="p-2 bg-white rounded-full w-fit">
              <Bell />
            </div>
            <div className="flex items-center bg-white rounded-[24px] py-1 px-2 gap-2">
              <img src={""} alt={"image"} className="w-9 h-9 rounded-full" />
              <h1 className="text-sm md:text-lg text-[#292D32] font-normal">
                hello
              </h1>
            </div>
          </div>
        </div>

        {/* Steps Navigation */}
        <div className="flex flex-wrap items-center gap-4 bg-gray-100 py-3">
          <div className="flex items-center ">
            <div className="flex- flex items-center justify-center text-center w-[289px] h-10 rounded-l-2xl bg-[#008847] text-white">
              Private Tutoring Permit
            </div>
            <svg
              width="43"
              height="55"
              viewBox="0 0 43 55"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 -ml-3"
            >
              <path
                d="M42.1587 27.5001L26.2198 55L1.41057e-06 55L0 -7.06319e-07L26 -7.06319e-07L42.1587 27.5001Z"
                fill="#008847"
              />
            </svg>
          </div>

          <div className="flex items-center ">
            <svg
              width="34"
              height="57"
              viewBox="0 0 34 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 -mr-2"
            >
              <path
                d="M15.0678 29.2041C15.3474 28.7147 15.4872 28.47 15.486 28.202C15.4847 27.9341 15.3427 27.6907 15.0586 27.2039L1.25561 3.55341C0.475866 2.21737 0.0859965 1.54936 0.374277 1.04733C0.662558 0.545292 1.43602 0.545292 2.98295 0.545292L31.5109 0.545291C32.4537 0.54529 32.9251 0.54529 33.218 0.838183C33.5109 1.13108 33.5109 1.60248 33.5109 2.54529L33.5109 54.4548C33.5109 55.3976 33.5109 55.869 33.218 56.1619C32.9251 56.4548 32.4537 56.4548 31.5109 56.4548L2.94592 56.4548C1.41339 56.4548 0.64713 56.4548 0.358078 55.9568C0.0690259 55.4588 0.449126 54.7934 1.20933 53.4627L15.0678 29.2041Z"
                fill="#008847"
                fill-opacity="0.2"
              />
            </svg>
            <div className="flex- flex items-center justify-center text-center w-[250px] h-10 bg-[#c3ded1] text-[#008847]">
              How to aplly?
            </div>
            <svg
              width="45"
              height="57"
              viewBox="0 0 45 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 -ml-2"
            >
              <path
                d="M44.0923 28.4999L27.618 56.4547L0.962072 56.4547L0.962074 0.545184L27.618 0.545165L44.0923 28.4999Z"
                fill="#008847"
                fill-opacity="0.2"
              />
            </svg>
          </div>

          <div className="flex items-center ">
            <svg
              width="34"
              height="57"
              viewBox="0 0 34 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 -mr-2"
            >
              <path
                d="M15.0678 29.2041C15.3474 28.7147 15.4872 28.47 15.486 28.202C15.4847 27.9341 15.3427 27.6907 15.0586 27.2039L1.25561 3.55341C0.475866 2.21737 0.0859965 1.54936 0.374277 1.04733C0.662558 0.545292 1.43602 0.545292 2.98295 0.545292L31.5109 0.545291C32.4537 0.54529 32.9251 0.54529 33.218 0.838183C33.5109 1.13108 33.5109 1.60248 33.5109 2.54529L33.5109 54.4548C33.5109 55.3976 33.5109 55.869 33.218 56.1619C32.9251 56.4548 32.4537 56.4548 31.5109 56.4548L2.94592 56.4548C1.41339 56.4548 0.64713 56.4548 0.358078 55.9568C0.0690259 55.4588 0.449126 54.7934 1.20933 53.4627L15.0678 29.2041Z"
                fill="#008847"
                fill-opacity="0.2"
              />
            </svg>
            <div className="flex- flex items-center justify-center text-center w-[250px] h-10 bg-[#c3ded1] text-[#008847]">
              Where do I aplly?
            </div>
            <svg
              width="45"
              height="57"
              viewBox="0 0 45 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 -ml-2"
            >
              <path
                d="M44.0923 28.4999L27.618 56.4547L0.962072 56.4547L0.962074 0.545184L27.618 0.545165L44.0923 28.4999Z"
                fill="#008847"
                fill-opacity="0.2"
              />
            </svg>
          </div>

          <div className="flex items-center ">
            <svg
              width="34"
              height="57"
              viewBox="0 0 34 57"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
              className="h-10 -mr-2"
            >
              <path
                d="M15.3543 28.7027C15.5315 28.3925 15.5298 28.0112 15.3496 27.7026L0.377804 2.04935C-0.0112719 1.38269 0.469586 0.545292 1.24147 0.545292L32.5109 0.54529C33.0632 0.54529 33.5109 0.993006 33.5109 1.54529L33.5109 55.4548C33.5109 56.0071 33.0632 56.4548 32.5109 56.4548L1.22296 56.4548C0.455187 56.4548 -0.0261862 55.6254 0.354663 54.9588L15.3543 28.7027Z"
                fill="#008847"
              />
            </svg>
            <div className="flex- flex items-center justify-center text-center w-[250px] h-10 rounded-r-2xl bg-[#008847] text-white">
              Apply Online Now
            </div>
          </div>
        </div>

        {/* Main Content */}
        <div className="p-6 space-y-6 rounded-[50px] bg-white">
          {/* Section 1 */}
          <div>
            <h2 className="text-xl font-bold mb-2">About the permit</h2>
            <p className="text-gray-700">
              A permit has been introduced to protect the rights of private
              teachers and to "ensure students receive supplemental education
              that meets their learning style and needs", said Muhammad Al
              Mualla, undersecretary for academic affairs at the Ministry of
              Education. The move is also intended to combat illegal private
              tuition.
            </p>
            <p className="text-gray-700 mt-2">
              “The introduction of a permit for individuals qualified to provide
              private lessons will help curb illegal and unregulated practices
              when recruiting private teachers, which risk affecting the
              learning process as a whole,” Mr Al Mualla added.
            </p>
          </div>

          {/* Section 2 */}
          <div>
            <h2 className="text-lg font-semibold mb-2">
              How much does it cost?
            </h2>
            <p className="text-gray-700">
              The permit is free and is valid for two years, after which holders
              can apply for it to be renewed. Rather than a fee, educators are
              asked to sign a code of conduct document approved by the Ministry
              of Human Resources and Emiratisation.
            </p>
          </div>

          {/* Section 3 */}
          <div>
            <h2 className="text-lg font-semibold mb-2">Who is eligible?</h2>
            <p className="text-gray-700">
              The new permit allows “different groups of specialised and
              qualified professionals from the community to offer private
              lessons for students, individually or in groups,” the Ministry of
              Human Resources and Emiratisation says.
            </p>
          </div>

          {/* Section 4 */}
          <div>
            <h2 className="text-lg font-semibold mb-2">
              It is open to all over-15s to apply, subject to approval.
            </h2>
            <ul className="list-disc">
              <li>Registered teachers in government or private schools</li>
              <li>Employees in the government and private sectors</li>
              <li>Unemployed individuals</li>
              <li>School students aged 15 to 18</li>
              <li>University students</li>
            </ul>
          </div>

          {/* Section 5 */}
          <div>
            <h2 className="text-lg font-semibold mb-2">How to apply?</h2>
            <h2 className="text-base font-semibold mb-2">
              What documents do you need?
            </h2>
            <p>
              Documents required vary depending on your current position and
              status.
            </p>
            <h2 className="text-lg font-semibold mb-2">
              A. If you’re a student (university student/school student) you
              need:
            </h2>
            <ul className="list-disc">
              <li>
                A certificate of continuity of study for a student in the
                secondary stage/indicating that the student is enrolled at the
                university
              </li>
              <li>The student’s last academic certificate</li>
              <li>No Objection Certificate (NOC) from the guardian</li>
              <li>Certificate of good conduct</li>
              <li>Medical fitness certificate</li>
              <li>
                Valid identification documents (passport/residence visa/Emirates
                ID)
              </li>
              <li>A clear personal photo with a white background </li>
            </ul>
            <h2 className="text-lg font-semibold mb-2">
              B. If you’re unemployed and are not a student you need:
            </h2>
            <ul className="list-disc">
              <li>Latest academic degree</li>
              <li>Certificate of good conduct</li>
              <li>
                Valid identification documents (passport/residence visa/Emirates
                ID)
              </li>
              <li>Medical fitness certificate</li>
              <li>Experience certificate (if applicable)</li>
              <li>A clear personal photo with a white background</li>
            </ul>
            <h2 className="text-lg font-semibold mb-2">
              C. If you’re employed but don’t work in education directly
            </h2>
            <ul className="list-disc">
              <li>Latest academic degree</li>
              <li>Certificate of good conduct</li>
              <li>No Objection Certificate (NOC) from the employer</li>
              <li>Medical fitness certificate</li>
              <li>Experience certificate (if applicable)</li>
              <li>
                Valid identification documents (passport/residence visa/Emirates
                ID)
              </li>
              <li>A clear personal photo with a white background</li>
            </ul>
            <h2 className="text-lg font-semibold mb-2">
              D. If you’re a teacher registered in a public or private school
            </h2>
            <ul className="list-disc">
              <li>Certificate of good conduct</li>
              <li>No Objection Certificate (NOC) from the employer</li>
              <li>Medical fitness certificate</li>
              <li>Experience certificate (if any)</li>
              <li>
                Valid identification documents (passport/residence visa/Emirates
                ID)
              </li>
              <li>A clear personal photo with a white background</li>
            </ul>
          </div>

          {/* Section 6 */}
          <div>
            <h2 className="text-lg font-semibold mb-2">
              What if you don’t apply?
            </h2>
            <p>
              Anyone offering private lessons without a permit will be subject
              to fines and penalties. The cost of these penalties has not been
              confirmed but, when private tuition was previously banned,
              educators could face fines as high as Dh 50,000.
            </p>
            <h2 className="text-lg font-semibold mb-2">
              Can you work from home?
            </h2>
            <p>
              A single permit covers online and in-person tutoring. Educators
              can also work from their home country, provided they have a valid
              residency.
            </p>
            <h2 className="text-lg font-semibold mb-2">Where do I apply?</h2>
            <p>
              A single permit covers online and in-person tutoring. Educators
              can also work from their home country, provided they have a valid
              residency.
            </p>
            <p>
              You can apply{" "}
              <a
                className="no-underline"
                href="https://publicservices.mohre.gov.ae/UserNotifications/MohrePrivateTeacherWorkPermit"
              >
                online
              </a>{" "}
              The process is simple:
            </p>
            <p>
              1. Enter your Emirates ID number and then click on ‘Send OTP’
              (One-time password).
            </p>
            <ul className="list-disc">
              <li>
                The system will then show you some of the details on your file
                with the Ministry, including your full name, nationality, date
                of birth and phone number.
              </li>
            </ul>
            <p>
              2. You will then have four categories under which you can apply
              for the permit:
            </p>
            <ul className="list-disc">
              <li>Student category (university student/school student)</li>
              <li>Category of the unemployed</li>
              <li>Category of workers in different sectors</li>
              <li>A teacher registered in a public or private school</li>
            </ul>
            <p>
              3. Select the category relevant to you and upload the required
              documents.
            </p>
            <p>
              4. Download the ‘Code of Conduct’ document next, sign it and
              upload a digital copy to attach to your application.
            </p>
            <p>5.Click on ‘Submit’.</p>
          </div>

          {/* {section 7} */}
          <div>
            <div>
              <h2 className="text-lg font-bold mb-2">
                How long does it take to get the Private Tutor visa?
              </h2>
              <p>The application will be processed within five working days.</p>
            </div>

            <div>
              <h2 className="text-lg font-bold mb-2">
                It has been more than 5 days, and I have not heard anything?
              </h2>
              <p>
                You can reach out to MOHRE through their{" "}
                <a
                  href="https://support.mohre.gov.ae/"
                  className="no-underline"
                >
                  support website
                </a>{" "}
                - or by calling them on 600 590000.
              </p>
            </div>

            <div>
              <h2 className="text-lg font-bold mb-2">
                My application was declined, may I reapply?
              </h2>
              <p>Yes, but only after a period of six months.</p>
            </div>

            <div>
              <h2 className="text-lg font-bold mb-2">
                How long is the permit valid for?
              </h2>
              <p>
                Once your application is approved, your permit will be valid for
                two years, and needs to be renewed accordingly.
              </p>
            </div>

            <div>
              <h2 className="text-lg font-bold mb-2">
                What are the permit fees?
              </h2>
              <p>The permit application is free of cost.</p>
            </div>

            <div>
              <h2 className="text-lg font-bold mb-2">
                If I get a license, can I work from my home country?
              </h2>
              <p>
                According to MOHRE, this is also an option available to
                residents, provided that your UAE residence visa is valid, and
                you comply with the agreements between the two parties.
              </p>
            </div>

            <div>
              <h2 className="text-lg font-bold mb-2">
                Can I use this permit to take online classes?
              </h2>
              <p>
                According to MOHRE, this permit covers both online and in-person
                activities.
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
