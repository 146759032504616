import BannerSection from '../components/AboutPages/Banner'
import Section2 from '../components/PrivacyPages/Section2'
import React from 'react'
import bannerImage from '../images/asserts/Website-Assets/Terms & Conditions/Hero_image.webp'
const Terms = () => {
    return (
        <main>
            <BannerSection title='Terms & Conditions' image={bannerImage} />
            <Section2 />
        </main>
    )
}

export default Terms