import { Facebook, Linkedin, Mail, Twitter } from 'lucide-react';
import React from 'react';
import { useTranslation } from "react-i18next";
import { Link } from 'react-router-dom';
import bg from "../../images/asserts/Website-Assets/HomePage/Hero_image.webp";

const Hero: React.FC = () => {
  const { t }: { t: (key: string) => string } = useTranslation();
  return (
    <div
      className="lg:h-[120vh] w-full lg:bg-top bg-center flex justify-center items-start"
      style={{
        backgroundImage: `url(${bg})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        backgroundAttachment: "scroll",
      }}
    >
      <div className="h-full w-full flex justify-between items-center lg:max-w-[90%] mx-auto lg:py-48 pt-40 pb-28 max-w-[92%]">
        <div className="flex justify-end flex-col items-start md:gap-4 gap-2 text-white lg:w-1/2 h-full w-full">
          <span className="font-bold xl:text-xl text-xl">{t('hero')}</span>
          <h1 className="lg:text-[50px] xl:text-[80px] text-[50px] w-full lg:leading-[65px] xl:leading-[95px] lg:w-full">
            {t('trustedTutors')}
            <span className='text-green-700'> {t('trustedTutors2')}</span>
            <span className=""> {t('from')}</span>
          </h1>
          <p className="lg:text-2xl text-xl">{t('customizedLearning')}</p>
          <Link to='/tutor'>
            <button className="px-6 py-2 bg-white text-xl text-black rounded-full bt-hover ">
              {t('how_it_works')}
            </button>
          </Link>
        </div>
        <div className="hidden lg:flex flex-col gap-4">
          <div className="bg-white p-2 rounded-full">
            <Mail />
          </div>
          <div className="bg-white p-2 rounded-full">
            <Facebook />
          </div>
          <div className="bg-white p-2 rounded-full">
            <Twitter />
          </div>
          <div className="bg-white p-2 rounded-full">
            <Linkedin />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Hero;
