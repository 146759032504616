import React, { useState, useRef } from "react";
import { ChevronDown } from "lucide-react";

const schools = [
  { id: 1, name: "Abdulla Bin Nasser Boys' School - Cycle 2", type: "Public" },
  { id: 2, name: "Al Ain International School", type: "Private" },
  { id: 3, name: "Brighton College Abu Dhabi", type: "Private" },
  { id: 4, name: "Cranleigh Abu Dhabi", type: "Private" },
  { id: 5, name: "Emirates National School", type: "Public" },
];

const School = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState(schools[0]);
  const dropdownRef = useRef(null);

  const handleSchoolSelect = (school:{ id: number; name: string; type: string; }) => {
    setSelectedSchool(school);
    setIsOpen(false);
  };

  return (
    <div className="space-y-8">
      <div>
        <h1 className="text-2xl text-[#272727] font-semibold">School</h1>
      </div>
      <div>
        <h1 className="text-[#272727] text-lg font-normal">
          Please choose your school
        </h1>
      </div>
      <div className="relative w-full max-w-md" ref={dropdownRef}>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="w-full px-3 py-2 bg-white border border-gray-200 rounded-lg flex items-center justify-between "
        >
          <div className="flex items-center gap-2">
            <span className="text-base font-medium">{selectedSchool.name}</span>
          </div>
          <ChevronDown
            className={`w-5 h-5 text-gray-400 transition-transform ${
              isOpen ? "transform rotate-180" : ""
            }`}
          />
        </button>

        {isOpen && (
          <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg">
            <ul className="py-1 max-h-60 overflow-auto">
              {schools.map((school) => (
                <li
                  key={school.id}
                  onClick={() => handleSchoolSelect(school)}
                  className="flex items-center gap-2 px-3 py-2 hover:bg-gray-50 cursor-pointer"
                >
                  <span className="text-sm font-medium">{school.name}</span>
                </li>
              ))}
            </ul>
          </div>
        )}
      </div>
    </div>
  );
};

export default School;
