import React from "react";
import image from "../../images/Homepageassets/6thSectionBg.png";
import { Bell } from "lucide-react";

const AvailabilityCalendar = () => {
  const availability = [
    { day: "Mon", times: ["6:00", "9:00", "12:00", "15:00", "18:00"] },
    { day: "Tue", times: ["6:30", "9:30", "12:30", "15:30", "18:30"] },
    { day: "Wed", times: ["7:00", "10:00", "13:00", "16:00", "19:00"] },
    { day: "Thu", times: ["7:30", "10:30", "13:30", "16:30", "19:30"] },
    { day: "Fri", times: ["8:00", "11:00", "14:00", "17:00", "20:00"] },
    { day: "Sat", times: ["8:30", "11:30", "14:30", "17:30", "20:30"] },
    { day: "Sun", times: ["8:30", "11:30", "14:30", "17:30", "20:30"] },
  ];

  return (
    <div className="space-y-10">
      <div className="flex justify-between items-center">
        <h1 className="text-[#000606] font-normal text-3xl">Availabilty</h1>
        <div className="flex items-center gap-3">
          <div className="p-2 bg-white rounded-full w-fit">
            <Bell />
          </div>
          <div className="flex items-center bg-white rounded-[24px] py-1 px-2 gap-2">
            <img src={""} alt={"image"} className="w-9 h-9 rounded-full" />
            <h1 className="text-sm md:text-lg text-[#292D32] font-normal">
              hello
            </h1>
          </div>
        </div>
      </div>
      <div className="grid grid-cols-7 gap-4">
        {availability.map(({ day, times }) => (
          <div key={day} className="col-span-1">
            <p className="font-medium text-[#008847]">{day}</p>
            {times.map((time) => (
              <div
                key={time}
                className="bg-[#008847] relative text-white mt-2 text-center py-1  rounded"
              >
                <img src={image} alt="bgImage"/>
                <p className="absolute left-[10%] top-[20%]">{time}</p>
              </div>
            ))}
          </div>
        ))}
      </div>

      <div className="flex items-end justify-end">
        <button className="bg-[#008847] border-4 border-[#62b38c] px-3 py-2 rounded-[28px] text-white" >Save and Continue</button>
      </div>
    </div>
  );
};

export default AvailabilityCalendar;
