import React, { useState, useEffect } from "react";
import colonimage from "../../images/Tutor/semicolon.png";
import client6 from "../../images/Tutor/6.png";
import client2 from "../../images/Tutor/2.png";
import client3 from "../../images/Tutor/3.png";
import client4 from "../../images/Tutor/4.png";
import client5 from "../../images/Tutor/5.png";
import { useTranslation } from "react-i18next";

const ReviewCard = ({ name, grade, review, imagePath, isMobile }) => (
  <div
    className={`reviewsParent ${
      isMobile ? "w-full" : "w-[800px]"
    } h-auto sm:h-[350px] flex-shrink-0 bg-white rounded-2xl shadow-lg overflow-hidden`}
  >
    <div className="flex h-full flex-col sm:flex-row items-center">
      <div className="flex-1 p-6 sm:p-8 flex flex-col justify-center">
        <img
          src={colonimage}
          alt="imag"
          className="mb-4 sm:mb-6 w-[24px] sm:w-[74px] h-[24px] sm:h-[74px]"
        />
        <p className="text-[#12182D] text-sm sm:mr-24 sm:text-lg leading-[30px] mb-4 sm:mb-6">
          {review}
        </p>
        <div className="mt-4 sm:mt-auto">
          <p className="font-semibold text-xl text-gray-800">{name}</p>
          <p className="text-[#ED1D24] font-semibold uppercase text-sm">
            Grade {grade}
          </p>
        </div>
      </div>
      {!isMobile && (
        <div className="hidden sm:block w-60 h-60 rounded-full relative">
          <img
            src={imagePath}
            alt={`${name}'s review`}
            className="absolute inset-0 w-full h-full object-cover rounded-full p-6"
          />
        </div>
      )}
    </div>
  </div>
);

const ReviewsCarousel = () => {
  const { t } = useTranslation();
  const [currentIndex, setCurrentIndex] = useState(0);
  const [isMobile, setIsMobile] = useState(false);
  const [touchStart, setTouchStart] = useState(null);
  const [touchEnd, setTouchEnd] = useState(null);

  const reviews = [
    {
      name: "Ahmad Ali",
      grade: "11",
      review:
        "In a few months, I went from floundering in math to earning an A+! My teacher offered me useful advice on how to solve problems and very precisely described ideas. For my academics, Nadwa Master has been a godsaver.",
      imagePath: client3,
    },
    {
      name: " Zara Tariq.",
      grade: "10",
      review:
        "My physics marks were hardly passing before I joined Nadwa. Now among the best students in my class thanks to my tutor's individualized direction. I never have felt so sure about my academics!",
      imagePath: client2,
    },
    {
      name: "Ali Raza",
      grade: "12",
      review:
        "My perspective on chemistry has totally changed since I have started tutoring utilizing Nadwa Master. From a C to constant A's, this system has changed my life.",
      imagePath: client5,
    },
    {
      name: "Sana Malik",
      grade: "12",
      review:
        "Learning online with Nadwa has been considerably superior to my past in-person instruction. The sessions are interactive, adaptable, and so much more practical. With a tutor who really cares about my development, I can at last study at my own speed.",
      imagePath: client4,
    },
    {
      name: "Hamid Sheikh",
      grade: "12",
      review:
        "The platform is great! It’s simple to use, the teachers are extremely skilled, and the number of disciplines provided is astounding. Nadwa Master has helped me attain my objectives and feel more secure in my talents.",
      imagePath: client6,
    },
  ];

  const totalReviews = reviews.length;
  const carouselReviews = [reviews[totalReviews - 1], ...reviews, reviews[0]];

  useEffect(() => {
    const checkMobile = () => {
      setIsMobile(window.innerWidth < 640);
    };
    checkMobile();
    window.addEventListener("resize", checkMobile);
    return () => window.removeEventListener("resize", checkMobile);
  }, []);

  useEffect(() => {
    const interval = setInterval(() => {
      if (isMobile) {
        setCurrentIndex((prev) => (prev + 1) % totalReviews);
      } else {
        setCurrentIndex((prev) => prev + 1);
      }
    }, 3000);
    return () => clearInterval(interval);
  }, [isMobile, totalReviews]);

  useEffect(() => {
    if (!isMobile) {
      if (currentIndex === totalReviews + 1) {
        setTimeout(() => setCurrentIndex(1), 0);
      } else if (currentIndex === 0) {
        setTimeout(() => setCurrentIndex(totalReviews), 0);
      }
    }
  }, [currentIndex, totalReviews, isMobile]);

  const handleTouchStart = (e) => {
    setTouchStart(e.touches[0].clientX);
  };

  const handleTouchMove = (e) => {
    setTouchEnd(e.touches[0].clientX);
  };

  const handleTouchEnd = () => {
    if (!touchStart || !touchEnd) return;

    const distance = touchStart - touchEnd;
    const isLeftSwipe = distance > 50;
    const isRightSwipe = distance < -50;

    if (isLeftSwipe) {
      setCurrentIndex((prev) => (prev + 1) % totalReviews);
    }
    if (isRightSwipe) {
      setCurrentIndex((prev) => (prev - 1 + totalReviews) % totalReviews);
    }

    setTouchStart(null);
    setTouchEnd(null);
  };

  return (
    <div className="pt-10 space-y-10 overflow-hidden">
      <h1 className="text-3xl px-6 sm:px-10 font-bold">{t('Reviews')}</h1>

      {/* Mobile View */}
      <div
        className="sm:hidden px-4"
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={handleTouchEnd}
        dir="ltr"
      >
        <div
          className="transition-transform duration-700 ease-in-out"
          style={{
            transform: `translateX(-${currentIndex * 100}%)`,
          }}
        >
          <div className="flex gap-2 sm:gap-0">
            {reviews.map((review, index) => (
              <ReviewCard key={index} {...review} isMobile={isMobile} />
            ))}
          </div>
        </div>
        <div className="flex justify-center mt-4 gap-2">
          {reviews.map((_, index) => (
            <div
              key={index}
              className={`h-2 w-2 rounded-full ${
                currentIndex === index ? "bg-green-600" : "bg-gray-300"
              }`}
            />
          ))}
        </div>
      </div>

      {/* Desktop Carousel */}
      <div
        className="hidden sm:block relative w-full py-10 h-full mx-auto overflow-hidden"
        dir="ltr"
      >
        <div
          className="flex transition-transform duration-700 ease-in-out gap-10"
          style={{
            transform: `translateX(-${currentIndex * 665}px)`,
            transition:
              currentIndex === 0 || currentIndex === totalReviews + 1
                ? "none"
                : "transform 0.7s ease",
          }}
        >
          {carouselReviews.map((review, index) => (
            <ReviewCard key={index} {...review} isMobile={isMobile} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ReviewsCarousel;
