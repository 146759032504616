import Hero from '../components/HomepageComponents/Hero';
import Section2 from '../components/HomepageComponents/Section2';
import Section3 from '../components/HomepageComponents/Section3';
import Section4 from '../components/HomepageComponents/Section4';
import Section5 from '../components/HomepageComponents/Section5';
import Section6 from '../components/HomepageComponents/Section6';
import Section7 from '../components/HomepageComponents/Section7';
import Section8 from '../components/HomepageComponents/Section8';


const Home = () => {
  
  return (
    <div>
      <Hero />
      <Section2 />
      <Section3 />
      <Section4 />
      <Section5 />
      <Section6 />
      <Section7 />
      <Section8 />
    </div>
  );
};

export default Home;
