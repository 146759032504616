import {
  ChevronDown,
  Facebook,
  Instagram,
  Linkedin,
  MapPin,
  Youtube,
} from "lucide-react";
import React, { useState } from "react";
import axios from "axios"; // Import Axios
import bannerImage from "../images/asserts/Website-Assets/Contact Us/hero_image.webp";
import BannerSection from "../components/AboutPages/Banner";
import { useTranslation } from "react-i18next";
import CalendlyWidget from "../components/ui/CalendlyWidget";

const ContactForm = () => {
  const { t, i18n }: { t: (key: string) => string; i18n: any } = useTranslation();

  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    country: "",
    phone: "",
    countryCode: "+971",
    message: "",
    heardFrom: [],
  });

  const handleChange = (
    e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>
  ) => {
    const { name, value } = e.target;
    setFormData((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  const handleCountryCodeSelect = (code: string) => {
    setFormData((prev) => ({
      ...prev,
      countryCode: code,
    }));
    setIsDropdownOpen(false);
  };

  const handleCheckboxChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value, checked } = e.target;
    setFormData((prev: any) => ({
      ...prev,
      heardFrom: checked
        ? [...prev.heardFrom, value]
        : prev.heardFrom.filter((item: string) => item !== value),
    }));
  };

  const handleSubmit = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    try {
      const response = await axios.post(
        "https://api.dev.nadwa-uae.com/api/v2/contact_us/",
        {
          name: formData.name,
          email: formData.email,
          phone: `${formData.countryCode}${formData.phone}`,
          message: formData.message,
        }
      );
      console.log("Response:", response.data);
      // Handle success (e.g., show a success message, clear the form, etc.)
    } catch (error) {
      console.error("Error:", error);
      // Handle error (e.g., show an error message)
    }
  };

  const countryCodes = [
    { code: "+971", country: t("country1") },
    { code: "+1", country: t("country2") },
    { code: "+44", country: t("country3") },
    { code: "+91", country: t("country4") },
    { code: "+86", country: t("country5") },
    { code: "+966", country: t("country6") },
    { code: "+49", country: t("country7") },
    { code: "+33", country: t("country8") },
  ];

  const sources = [
    t("Facbook"),
    t("Linkiden"),
    t("Friends"),
    t("Instagram"),
    t("Twitter"),
    t("GoogleSearch"),
    t("Others"),
    t("Tiktok"),
    t("Snapchat"),
    t("Boards"),
  ];

  return (
    <div className="max-w-full ">
      <BannerSection title={t("contactHead")} image={bannerImage} />

      <div className="flex flex-col md:flex-row  bg-white ">
        {/* Left Section */}
        <div className="bg-[#008847] text-white space-y-12 p-8 md:w-1/3  z-20 relative lg:-mt-24 -mt-14">
          <div className="border-b border-gray-400 py-10 space-y-6">
            <h2 className="text-3xl font-bold">{t("follow")}</h2>
            <div className="flex gap-4">
              <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center">
                <Facebook className="text-[#008847]" />
              </div>
              <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center">
                <Instagram className="text-[#008847]" />
              </div>
              <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center">
                <Linkedin className="text-[#008847]" />
              </div>
              <div className="w-8 h-8 rounded-full bg-white flex items-center justify-center">
                <Youtube className="text-[#008847]" />
              </div>
            </div>
          </div>

          <div className="border-b border-gray-400 py-10 space-y-6">
            <h2 className="text-3xl font-bold ">{t("contactHead")}</h2>
            <div className="space-y-4">
              <p>{t("phone")}: +971 6 711 0000, +971 5 731 5000</p>
              <p>{t("fax")}: +971 5 731 0111</p>
              <p>{t("email")}: info@nadwa.ae</p>
            </div>
          </div>

          <div className=" space-y-6 py-10">
            <h2 className="text-2xl font-bold mb-4">{t("address")}</h2>
            <div className="flex gap-2">
              <MapPin className="w-12" />
              <p>{t("addressValue")}</p>
            </div>
          </div>
        </div>

        {/* Right Section */}



        <div className={`p-8 md:w-2/3 space-y-8 bg-gradient-to-tr from-[#fff] to-[#d3eadf] z-20 relative lg:-mt-24 -mt-14 ${i18n.dir(i18n.language) === 'rtl' ? 'rounded-tr-[0px] rounded-tl-[100px]' : 'rounded-tr-[100px]  rounded-tl-[0px]'}`}>
          <h1 className="text-5xl font-bold ">{t("formHead")}</h1>
          <div
          dir="ltr">
           < CalendlyWidget/>
          </div>
             
          {/* <form onSubmit={handleSubmit} className="space-y-6">
            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <input
                  type="text"
                  name="name"
                  placeholder={t("namePlaceholder")}
                  value={formData.name}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-[20px] bg-white focus:outline-none text-base font-normal"
                />
              </div>
              <div>
                <input
                  type="email"
                  name="email"
                  placeholder={t("EmailPlacholder")}
                  value={formData.email}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-[20px] bg-white focus:outline-none text-base font-normal"
                />
              </div>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
              <div>
                <input
                  type="text"
                  name="country"
                  placeholder={t("CountryPlacholder")}
                  value={formData.country}
                  onChange={handleChange}
                  className="w-full p-3 border rounded-[20px] bg-white focus:outline-none text-base font-normal"
                />
              </div>
              <div className="relative">
                <div className="flex">
                  <div className="relative">
                    <button
                      type="button"
                      className={`flex items-center gap-2 p-3 border ${i18n.dir(i18n.language) === 'rtl' ? 'rounded-l-[0px] rounded-r-[20px]' : 'rounded-l-[20px]'} bg-white focus:outline-none text-base font-normal`}
                      onClick={() => setIsDropdownOpen(!isDropdownOpen)}
                    >
                      {formData.countryCode}
                      <div className="flex items-center justify-center w-8 h-8 text-white bg-[#80c3a3] rounded-full">
                        <ChevronDown className="w-4 h-4" />
                      </div>
                    </button>

                    {isDropdownOpen && (
                      <div className="absolute z-10 mt-1 w-48 bg-white border rounded-lg shadow-lg">
                        {countryCodes.map((country) => (
                          <button
                            key={country.code}
                            type="button"
                            className="w-full px-4 py-2 text-left hover:bg-gray-100 focus:outline-none"
                            onClick={() =>
                              handleCountryCodeSelect(country.code)
                            }
                          >
                            {country.country} ({country.code})
                          </button>
                        ))}
                      </div>
                    )}
                  </div>
                  <input
                    type="tel"
                    name="phone"
                    placeholder={t("PhonePlacholder")}
                    value={formData.phone}
                    onChange={handleChange}
                    className={` w-full p-3 border ${i18n.dir(i18n.language) === 'rtl' ? 'rounded-r-[0px] rounded-l-[20px]' : 'rounded-r-[20px]'} bg-white focus:outline-none text-base font-normal`}
                  />
                </div>
              </div>
            </div>

            <div>
              <textarea
                name="message"
                placeholder={t("textAreHolder")}
                value={formData.message}
                onChange={handleChange}
                rows={6}
                className="w-full p-3 border rounded-[20px] bg-white focus:outline-none text-base font-normal"
              />
            </div>

            <div className="space-y-6">
              <h3 className="text-2xl  font-bold ">{t("howHear")}</h3>
              <div className="grid grid-cols-2 md:grid-cols-4 gap-4">
                {sources.map((source) => (
                  <label key={source} className="flex items-center space-x-2">
                    <input
                      type="checkbox"
                      value={source}
                      onChange={handleCheckboxChange}
                      className="rounded"
                    />
                    <span className="text-xl font-normal">{source}</span>
                  </label>
                ))}
              </div>
            </div>

            <button
              type="submit"
              className="bg-[#ED1D24] text-white px-16 py-3 rounded-[45px] hover:bg-red-600"
            >
              {t("submit")}
            </button>
          </form>  */}
        </div>
      </div>
    </div>
  );
};

export default ContactForm;
