import EditProfileTutorSidebar from "../components/EditProfileComponents/EditProfileTutorSideBar";
import BasicsProfile from "../components/EditProfileComponents/Tabs/BasicsProfile";
import React, { useState } from "react";
import Country from "../components/EditProfileComponents/Tabs/Country";
import School from "../components/EditProfileComponents/Tabs/School";
import Experience from "../components/EditProfileComponents/Tabs/Experience";
import TravelTime from "../components/EditProfileComponents/Tabs/TravelTime";
import Qualifications from "../components/EditProfileComponents/Tabs/Qualifications";
import TeachingSubjects from "../components/EditProfileComponents/Tabs/TeachingSubjects";
import Pricing from "../components/EditProfileComponents/Tabs/Pricing";
import RecordVideo from "../components/EditProfileComponents/Tabs/RecordVideo";

function EditProfileTutor() {
  const [activeTab, setActiveTab] = useState("basics");

  const handleTabChange = (tab:string) => {
    setActiveTab(tab);
  };

  return (
    <div className="flex items-center justify-center bg-[#e4e4e4] py-20">
      <div className="bg-white rounded-lg shadow space-y-16 p-6 w-[90%]">
        <div className="flex flex-col space-y-4 ">
          <h1 className="text-black font-bold text-2xl ">Account Settings</h1>
          <div>
            <div className="w-full bg-gray-200 rounded-full h-2">
              <div className="bg-[#008847] h-2 rounded-full w-[50%]"></div>
            </div>
          </div>
        </div>
        {/* TABS & CONTENT */}
        <div className="flex">
          <EditProfileTutorSidebar
            activeTab={activeTab}
            onTabChange={handleTabChange}
          />
          <div className="flex-1 space-y-4 p-6">
            {activeTab === "basics" && <BasicsProfile />}
            {activeTab === "country" && <Country />}
            {activeTab === "school" && <School />}
            {activeTab === "experience" && <Experience />}
            {activeTab === "travel" && <TravelTime />}
            {activeTab === "qualifications" && <Qualifications />}
            {activeTab === "subjects" && <TeachingSubjects />}
            {activeTab === "pricing" && <Pricing />}
            {activeTab === "video" && <RecordVideo />}
            {activeTab === "availability" && <div>Availability</div>}
          </div>
        </div>
      </div>
    </div>
  );
}

export default EditProfileTutor;
