import { ChevronDown } from "lucide-react";
import React, { useRef, useState } from "react";

function TeachingSubjects() {
  const subjects = [
    { id: 1, name: "English" },
    { id: 2, name: "Chemistry" },
    { id: 3, name: "Biology" },
    { id: 4, name: "Mathematics" },
    { id: 5, name: "Computer Science" },
  ];

  const [isOpen, setIsOpen] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState<{ id: number; name: string }[]>([]);
  const dropdownRef = useRef(null);

  const handleSubjectSelect = (subject: { id: number; name: string }) => {
  setSelectedSubjects((prevSelected) =>
    prevSelected.some((s) => s.id === subject.id)
      ? prevSelected.filter((s) => s.id !== subject.id)
      : [...prevSelected, subject]
  );
};

return (
  <div className="space-y-8">
    <h1 className="text-2xl text-[#272727] font-semibold">Subjects</h1>
    <div>
      <h1 className="text-[#272727] text-lg font-normal">
        Which subjects and levels would you like to teach?
      </h1>
    </div>
    <div className="relative w-full max-w-md" ref={dropdownRef}>
      <button
        onClick={() => setIsOpen(!isOpen)}
        className="w-full px-3 py-2 bg-white border border-gray-200 rounded-lg flex items-center justify-between"
      >
        <div className="flex items-center gap-2 flex-wrap">
          {selectedSubjects.length > 0 ? (
            selectedSubjects.map((subject) => (
              <span
                key={subject.id}
                className="bg-[#008847]  text-white px-3 py-1 rounded-xl text-sm"
              >
                {subject.name}
              </span>
            ))
          ) : (
            <span className="text-base font-medium text-gray-500">
              Select subjects
            </span>
          )}
        </div>
        <ChevronDown
          className={`w-5 h-5 text-gray-400 transition-transform ${isOpen ? "transform rotate-180" : ""
            }`}
        />
      </button>

      {isOpen && (
        <div className="absolute z-10 w-full mt-1 bg-white border border-gray-200 rounded-lg">
          <ul className="py-1 max-h-60 overflow-auto">
            {subjects.map((subject) => (
              <li
                key={subject.id}
                onClick={() => handleSubjectSelect(subject)}
                className={`flex items-center gap-2 px-3 py-2 cursor-pointer ${selectedSubjects.some((s) => s.id === subject.id)
                    ? "bg-blue-50 "
                    : "hover:bg-gray-50"
                  }`}
              >
                <input
                  type="checkbox"
                  checked={selectedSubjects.some((s) => s.id === subject.id)}
                  onChange={() => handleSubjectSelect(subject)}
                  className="form-checkbox"
                />
                <span className="text-sm font-medium">{subject.name}</span>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  </div>
);
}

export default TeachingSubjects;
