import React, { useState, useEffect } from "react";
import axios from "axios";
import Sidebar from "../components/DashBoardComponents/SideBar";
import ProfileCard from "../components/DashBoardComponents/ProfileCard";
import { Bell } from "lucide-react";
import TutoringPermit from "../components/ApplyForPermitTutor/ApplyForPermit";
import AvailabilityCalendar from "../components/AvailabilityTutor/AVailabiltyTutor";

type Job = {
  student: {
    first_name: string;
    last_name: string;
  };
  job_post: {
    subject: string;
    level: string;
    start_date: string;
  };
  status: string;
};

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("dashboard");
  const [tutorData, setTutorData] = useState<any>([]);

  const [jobsData, setJobsData] = useState<Job[]>([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState("");

  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };

  useEffect(() => {
    const fetchTutorData = async () => {
      try {
        const response = await axios.get(
          "https://api.nadwa-uae.com/api/v2/tutor/profile/",
          {
            headers: {
              Authorization: `Token 9fd3a327ace3413db57491699d66ad03`,
            },
          }
        );
        setTutorData(response.data);
      } catch (err) {
        setError("Failed to fetch tutor data");
      } finally {
        setLoading(false);
      }
    };

    const fetchJobsData = async () => {
      try {
        const response = await axios.get(
          "https://api.nadwa-uae.com/api/v2/schoolsupply/applications/",
          {
            headers: {
              Authorization: `Token 9fd3a327ace3413db57491699d66ad03`,
            },
          }
        );

        setJobsData(response.data.results);
      } catch (err) {
        setError("Failed to fetch jobs data");
      }
    };

    fetchTutorData();
    fetchJobsData();
  }, []);

  const getStatusColor = (status: string) => {
    switch (status) {
      case "accepted":
        return "text-[#1A932E] bg-[#d6ebd9]";
      case "delayed":
        return "text-[#DFA510] bg-[#faf1da]";
      case "rejected":
        return "text-[#EE201C] bg-[#fcd7d6]";
      default:
        return "text-gray-500";
    }
  };

  if (loading) {
    return (
      <div className="flex justify-center items-center h-screen">
        Loading...
      </div>
    );
  }

  return (
    <div className="flex h-fit bg-gray-100">
      <Sidebar
        activeTab={activeTab}
        onTabChange={(tab) => handleTabChange(tab)}
      />
      <div className="flex-1 space-y-4 p-6">
        {activeTab === "dashboard" && (
          <div className="space-y-3">
            {error && <div className="text-red-500 mb-4">{error}</div>}
            <div className="flex justify-between items-center">
              <h1 className="text-[#000606] font-normal text-3xl">Dashboard</h1>
              <div className="flex items-center gap-3">
                <div className="p-2 bg-white rounded-full w-fit">
                  <Bell />
                </div>
                <div className="flex items-center bg-white rounded-[24px] py-1 px-2 gap-2">
                  <img
                    src={tutorData.photo}
                    alt={tutorData.user.first_name}
                    className="w-9 h-9 rounded-full"
                  />
                  <h1 className="text-sm md:text-lg text-[#292D32] font-normal">
                    {tutorData.user.first_name}
                  </h1>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              <div className="col-span-2 bg-white p-4 rounded-lg shadow">
                <h2 className="text-lg font-semibold">
                  Jobs you've applied for
                </h2>
                <div className="mt-4">
                  <table className="w-full text-left border-separate border-spacing-y-2">
                    <thead>
                      <tr>
                        <th className="p-2 border-b">Student</th>
                        <th className="p-2 border-b">Request</th>
                        <th className="p-2 border-b">Date</th>
                        <th className="p-2 border-b">Status</th>
                        <th className="p-2 border-b">Profile</th>
                      </tr>
                    </thead>
                    <tbody>
                      {jobsData.map((job: Job, index: number) => (
                        <tr key={index}>
                          <td className="p-2 text-[#060606] text-sm">
                            {job.student.first_name} {job.student.last_name}
                          </td>
                          <td className="p-2 text-[#060606] text-sm">
                            {job.job_post.subject} {job.job_post.level}
                          </td>
                          <td className="p-2 text-[#060606] text-sm">
                            {new Date(
                              job.job_post.start_date
                            ).toLocaleDateString("en-US", {
                              year: "numeric",
                              month: "short",
                              day: "numeric",
                            })}
                          </td>
                          <td>
                            <span
                              className={`p-2 font-medium rounded-xl ${getStatusColor(
                                job.status
                              )}`}
                            >
                              {job.status}
                            </span>
                          </td>
                          <td className="p-2 text-[#008847] w-fit font-medium text-sm cursor-pointer">
                            View
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <ProfileCard TutorData={tutorData} />
            </div>
          </div>
        )}
        {activeTab === "applypermit" && (
          <TutoringPermit/>
        )}
        {activeTab === "available" &&(
          <AvailabilityCalendar/>
        )} 
      </div>
    </div>
  );
};

export default Dashboard;
