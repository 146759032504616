import React, { useState } from 'react'
import * as SliderPrimitive from "@radix-ui/react-slider";
const Price = ({setShowBudgetPopup, setShowDays, min, setMin, max, setMax}:any) => {
    const marks = [50, 100, 150, 200, 250, 300, 350, 400];
    const [values, setValues] = useState([50, 400]);
    const handleValueChange = (newValues: any) => {
        const roundedValues = newValues.map((value: any) => {
            return marks.reduce((prev, curr) =>
                Math.abs(curr - value) < Math.abs(prev - value) ? curr : prev
            );
        });
        setValues(roundedValues);
        if (roundedValues[0] !== min || roundedValues[1] !== max) {
            setMin(roundedValues[0]);
            setMax(roundedValues[1]);
        }
    };
    const features = [
        {
            icon: (
                <div className="w-8 h-8 bg-green-600 rounded flex items-center justify-center">
                    <span className="text-[8px] text-white font-medium leading-tight">
                        FREE
                        <br />
                        TRIAL
                    </span>
                </div>
            ),
            title: "Free 'Best Fit' Trial",
            subtitle: "Lesson",
        },
        {
            icon: (
                <div className="w-8 h-8 bg-green-600 rounded flex items-center justify-center">
                    <svg viewBox="0 0 24 24" fill="none" className="w-5 h-5 text-white" stroke="currentColor" strokeWidth="2">
                        <path d="M16 21v-2a4 4 0 0 0-4-4H6a4 4 0 0 0-4 4v2" />
                        <circle cx="9" cy="7" r="4" />
                        <path d="M22 21v-2a4 4 0 0 0-3-3.87" />
                        <path d="M16 3.13a4 4 0 0 1 0 7.75" />
                    </svg>
                </div>
            ),
            title: "100% Satisfaction",
            subtitle: "Guarantee",
        },
        {
            icon: (
                <div className="w-8 h-8 bg-green-600 rounded flex items-center justify-center">
                    <svg viewBox="0 0 24 24" fill="none" className="w-5 h-5 text-white" stroke="currentColor" strokeWidth="2">
                        <path d="M21 12a9 9 0 0 1-9 9m9-9a9 9 0 0 0-9-9m9 9H3m9 9a9 9 0 0 1-9-9m9 9c1.657 0 3-4.03 3-9s-1.343-9-3-9m0 18c-1.657 0-3-4.03-3-9s1.343-9 3-9m-9 9a9 9 0 0 1 9-9" />
                    </svg>
                </div>
            ),
            title: "Free Customer Support",
            subtitle: "(forever!)",
        },
    ]

    return (
        <div className="fixed inset-0 bg-black/70 flex items-center justify-center p-4">
            <div className="w-full max-w-[50%]">
                <div className="bg-white rounded-[20px] overflow-hidden">
                    <div className="p-6">
                        <div className="relative w-full my-6">
                            <div className="h-2 bg-[#00884733] w-full" />
                            <div className="h-2 bg-green-600 w-[30%] absolute top-0 left-0" >
                                <div className="absolute -top-5 -right-5">
                                    <div className="bg-green-600 text-[10px] text-white px-2 py-0.5 rounded-full uppercase font-medium">30%</div>
                                </div>
                            </div>
                        </div>
                        <h2 className="text-xl">Choose Budget (Drag the slider to select)</h2>
                        <p className=" text-black mt-4">Price Range Per Hour (AED)</p>
                        <div className="lg:w-[90%] mx-auto px-2 mb-10 mt-6 md:block hidden ">
                            <div className="relative pb-10">
                                <SliderPrimitive.Root
                                    className="relative flex w-full touch-none select-none items-center"
                                    value={values}
                                    max={400}
                                    min={50}
                                    step={1}
                                    onValueChange={handleValueChange}
                                >
                                    {/* Track */}
                                    <SliderPrimitive.Track className="relative h-4 w-full grow overflow-hidden rounded-full bg-[#8F8F8F33]">
                                        {/* Range */}
                                        <SliderPrimitive.Range className="absolute h-full bg-[#008847]" />
                                    </SliderPrimitive.Track>

                                    {/* Thumbs */}
                                    <SliderPrimitive.Thumb
                                        className="block h-5 w-5 rounded-full border-2 border-green-600 bg-white"
                                        aria-label="Minimum value"
                                    />
                                    <SliderPrimitive.Thumb
                                        className="block h-5 w-5 rounded-full border-2 border-green-600 bg-white"
                                        aria-label="Maximum value"
                                    />
                                </SliderPrimitive.Root>

                                {/* Marks */}
                                <div className="absolute left-0 right-0 -bottom-2">
                                    <div className="relative h-8">
                                        {marks.map((mark) => (
                                            <div
                                                key={mark}
                                                className="absolute -translate-x-1/2 text-sm text-gray-600"
                                                style={{
                                                    left: `${((mark - 50) / (400 - 50)) * 100}%`,
                                                }}
                                            >
                                                {mark}
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="flex gap-3 w-1/3 mt-4">
                            <button
                                onClick={() => {
                                    setShowBudgetPopup(false);
                                    setShowDays(true);
                                }}
                                className="flex-1 py-2 px-4 bg-[#008847] text-white rounded-xl hover:bg-green-700 transition-colors"
                            >
                                Next
                            </button>
                        </div>
                    </div>
                </div>
                <div className="flex justify-between gap-4 p-4 bg-white rounded-[20px] shadow-lg mt-4">
                    {features.map((feature, index) => (
                        <div key={index} className="flex items-center gap-3 px-4 py-3 bg-[#e6f7ef] rounded-lg">
                            {feature.icon}
                            <div className="text-sm">
                                <div className="font-medium">{feature.title}</div>
                                <div>{feature.subtitle}</div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}

export default Price