
function Card({ image, title, id }: { image: string; title: string, id?: number }) {
    return (
        <a href={`/blogs/${id}`} className="no-underline space-y-5">
            <div className="w-[320px] h-[330px] rounded-2xl">
                <img className="w-full h-full rounded-2xl" alt="images" src={image} />
            </div>
            <div className='space-y-2'>
                <div className="no-underline w-[320px] left-0 text-black text-[22px] font-normal font-['Helvetica'] leading-7">{title}</div>
                <div className="no-underline text-[#ed1d24] text-base font-normal font-['Inter'] leading-tight">11 December 2024</div>
            </div>
        </a>
    );
};

export default Card