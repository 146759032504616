import React from "react";
import {
  Home,
  User,
  Briefcase,
  Inbox,
  Clock,
  Clipboard,
  User2,
  Settings,
  LogOut,
} from "lucide-react";
import LogoImage from "../../images/Homepageassets/Nadwa-Color.png";
import { Link } from "react-router-dom";

const Sidebar = ({
  activeTab,
  onTabChange,
}: {
  activeTab: string;
  onTabChange: (tab: any) => void;
}) => {
  const tabs = [
    { id: "dashboard", name: "Dashboard", icon: <Home /> },
    { id: "applypermit", name: "Apply for Permit", icon: <Clipboard /> },
    { id: "available", name: "Availability", icon: <Clock /> },
    { id: "jobs", name: "Jobs Board", icon: <Briefcase /> },
    { id: "inbox", name: "Inbox", icon: <Inbox /> },
    { id: "booking", name: "Bookings", icon: <User /> },
  ];
  return (
    <aside className=" bg-white rounded-lg shadow space-y-16 p-6">
      <img src={LogoImage} className=" w-40" alt="2nd Section" />
      <div className="space-y-10">
        <h1 className="font-medium text-[#808080] text-left text-sm">
          OVERVIEW
        </h1>
        <ul className="space-y-3">
          {tabs.map((tab) => (
            <li
              key={tab.id}
              onClick={() => onTabChange(tab.id)} 
              className={`flex items-center gap-4 p-2 cursor-pointer ${
                activeTab === tab.id
                  ? "bg-[#008847] rounded-[24px] font-normal text-white text-base"
                  : "text-black font-medium text-sm"
              }`}
            >
              {tab.icon}
              {tab.name}
            </li>
          ))}
        </ul>
      </div>
      <div>
        <h1 className="font-medium text-[#808080] text-left text-sm">
          SETTINGS
        </h1>
        <div className="text-black font-medium space-y-2">
          <Link to={"/EditProfile"} className="flex  items-center no-underline text-black p-2 cursor-pointer">
            <User2 className="w-20" />
            <h1 className="text-sm">View Profile</h1>
          </Link>
          <div className="flex items-center p-2 cursor-pointer">
            <Settings className="w-20" />
            <h1 className="text-sm">Account Settings</h1>
          </div>
          <div className="flex text-[#FD5823] items-center p-2 cursor-pointer">
            <LogOut className="w-20" />
            <h1 className="text-sm">Log Out</h1>
          </div>
        </div>
      </div>
    </aside>
  );
};

export default Sidebar;
