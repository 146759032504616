import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import { initReactI18next } from 'react-i18next';
import { locales } from './locales';

const i18nConfig = {
  debug: true,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  resources: {
    en: { translation: locales.en },
    ar: { translation: locales.ar },
  },
  lng: 'en', // Default language
};

i18n.use(LanguageDetector).use(initReactI18next).init(i18nConfig);

export default i18n;
