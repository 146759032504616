import React from 'react';
import { useNavigate } from 'react-router-dom';

const ErrorPage = () => {
  const navigate = useNavigate();

  const handleGoHome = () => {
    navigate('/');
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-[#008847]">
      <h1 className="text-8xl font-bold text-white mb-4">404</h1>
      <p className="text-lg text-white mb-6">
        Oops! The page you're looking for doesn't exist.
      </p>
      <button
        onClick={handleGoHome}
        className="px-6 py-2 text-white bg-[#ED1D24] hover:bg-[#ED1D24] rounded shadow"
      >
        Go Home
      </button>
    </div>
  );
};

export default ErrorPage;
