import { Link } from "react-router-dom";
import image from "../../images/AboutImages/Vector.png";
import { useTranslation } from "react-i18next";
const AboutLast = () => {
  const { t }: { t: (key: string) => string } = useTranslation();
  return (
    <section
      className="xl:py-48 py-40 flex justify-center items-center text-center relative z-10 overflow-hidden"
      style={{
        borderRadius: "100px 0px 0px 0px",
        background:
          "linear-gradient(180deg, rgba(0, 136, 71, 0.20) 0%, rgba(0, 136, 71, 0.00) 50%)",
      }}
    >
      <img
        src={image}
        alt="vector"
        className="absolute -top-6 z-[-2] opacity-25 w-96 sm:w-auto h-auto"
      />
      <div className="max-w-[80%]  sm:max-w-[70%] mx-auto">
        <h2 className="xl:text-6xl text-2xl sm:text-5xl font-normal">
          {t('discover')}
        </h2>
        <p className="xl:text-xl text-sm sm:text-xl xl:mt-5 mt-4 sm:sm:max-w-[80%] mx-auto">
          {t('discover2')}
        </p>
        <div className="flex gap-4 mt-10 items-center text-center justify-center">
          <Link
            to={"https://app.nadwa-uae.com/tutor/register/"}
            className="bg-[#ED1D24] w-40 h-10  sm:h-14 xl:w-56 items-center flex justify-center no-underline rounded-[40px] text-sm sm:text-xl text-white"
          >
            {t('discoverbt1')}
          </Link>
          <Link
            to={"https://app.nadwa-uae.com/student/register/"}
            className="bg-[#000000]  w-40 h-10  sm:h-14 xl:w-56 items-center flex justify-center no-underline rounded-[40px]  text-sm xl:text-xl text-white"
          >
            {t('discoverbt2')}
          </Link>
        </div>
      </div>
    </section>
  );
};

export default AboutLast;
