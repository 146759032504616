import React from "react";
import StepsData from "../components/HowItWorkComponents/StepData";
import BannerSection from "../components/AboutPages/Banner";
import bannerImage from "../images/asserts/Website-Assets/HowIt Work/howitwork_hero.png";
import { useTranslation } from "react-i18next";

function HowItWork() {
    const { t }: { t: (key: string) => string } = useTranslation();
  
  return (
    <div>
      <BannerSection title={t("HowItWorkHead")} image={bannerImage} />
      <StepsData />
      {/* Changes Steps */}
    </div>
  );
}

export default HowItWork;
