import React, { useState, useRef, useEffect } from "react";
import Card from "react-bootstrap/Card";
import carouselimage1 from "../../images/Homepageassets/5.png";
import carouselimage2 from "../../images/Homepageassets/6.png";
import carouselimage3 from "../../images/Homepageassets/7.png";
import { MoveRight, MoveLeft } from "lucide-react";
import { useTranslation } from "react-i18next";

function CustomCarousel() {
  const { t, i18n }: { t: (key: string) => string, i18n: any } = useTranslation();
  const isArabic = i18n.language === 'ar';
  const [currentIndex, setCurrentIndex] = useState(0);
  const [visibleItems, setVisibleItems] = useState(4); // Default visible items
  const [itemWidth, setItemWidth] = useState(25); // Default width percentage (100 / 4)

  const carouselRef = useRef(null);
  const autoplayInterval = useRef<NodeJS.Timeout | null>(null); // Correct type

  const carouselItems = [
    {
      id: 5,
      image: carouselimage1,
      title: 'Top 10 Benefits of Hiring a Private Tutor in Dubai',
      description: t('blogcard1date'),
    },
    {
      id: 4,
      image: carouselimage2,
      title: 'How to Find the Best Tutor Website in Dubai',
      description: t('blogcard2date'),
    },
    {
      id: 7,
      image: carouselimage3,
      title: 'Most Essential Study Tips for Students in Dubai',
      description: t('blogcard3date'),
    },
    {
      id: 5,
      image: carouselimage1,
      title: 'Modern Physics with AI: Revolutionize Your Physics Learning',
      description: t('blogcard4date'),
    },
    {
      id: 4,
      image: carouselimage2,
      title: 'Your guide to creating the perfect language learning schedule',
      description: t('blogcard5date'),
    },
  ];

  // Update visible items and item width based on window size
  const updateCarouselLayout = () => {
    const windowWidth = window.innerWidth;

    if (windowWidth >= 1024) {
      setVisibleItems(4); // 4 items visible for large screens
      setItemWidth(100 / 4); // Each item takes 25% width
    } else if (windowWidth >= 640) {
      setVisibleItems(4); // 3 items visible for medium screens
      setItemWidth(100 / 4); // Each item takes 33.33% width
    } else {
      setVisibleItems(2); // 1 item visible for very small screens
      setItemWidth(100); // Each item takes 100% width
    }
  };

  const handleNext = () => {
    setCurrentIndex((prevIndex) =>
      prevIndex + 1 < carouselItems.length - (visibleItems - 1)
        ? prevIndex + 1 : prevIndex - 1
      // Loop back to start
    );
};

const handlePrev = () => {
  setCurrentIndex((prevIndex) =>
    prevIndex > 0 ? prevIndex - 1 : carouselItems.length - Math.ceil(visibleItems)
  );
};

useEffect(() => {
  updateCarouselLayout(); // Set initial layout

  const handleResize = () => {
    updateCarouselLayout(); // Update layout on window resize
  };

  window.addEventListener("resize", handleResize); // Listen for window resize
  return () => {
    window.removeEventListener("resize", handleResize); // Cleanup on unmount
  };
}, []); // Empty dependency array, runs only on mount

useEffect(() => {
  let isMounted = true; // Flag to check if component is still mounted

    // Autoplay: move to the next slide every 5 seconds
    autoplayInterval.current = setInterval(() => {
      if (isMounted) {
        handleNext();
      }
    }, 2500);

  // Cleanup interval on unmount
  return () => {
    isMounted = false; // Prevent state updates after unmount
    if (autoplayInterval.current) {
      clearInterval(autoplayInterval.current); // Clear the interval
    }
  };
}, []);

return (
  <div className="relative w-full overflow-hidden border-none">
    {/* Carousel Items */}
    <div
      ref={carouselRef}
      className={`flex ${isArabic ? "flex-row-reverse" : ""} transition-transform duration-500 ease-in-out mt-28 border-none lg:gap-1 md:gap-20 gap-0 `}
      style={{
        transform: `translateX(-${currentIndex * itemWidth}%)`,
        width: `${(carouselItems.length / visibleItems) * 100}%`,
        border: "none", // Ensures no border on the carousel wrapper
      }}
    >
      {carouselItems.map((item, index) => (
        <div
          key={index}
          className={`flex-shrink-0 mt-[-120] flex flex-col border-none relative sm:left-0 left-6 w-[25%] ${isArabic ? "right-0 sm:right-4" : ""}`}
          style={{
            width: `${itemWidth}%`,
            border: "none", // Ensures no border on individual carousel items
          }}
        >
          <a href={`/blogs/${item.id}`} className="no-underline">
            <div className="overlay"></div>
            <Card
              className="flex flex-col 2xl:w-[500px] w-[320px] shadow-none border-none"
              style={{
                boxShadow: "none", // Removes shadow from Bootstrap card
                border: "none", // Removes border from Bootstrap card
              }}
            >
              <Card.Img
                variant="top"
                src={item.image}
                alt={item.title}
                height={50}
                className="rounded-none relative overlay-parent"
                style={{
                  border: "none", // Ensures no border on the card image
                }}
              />
              <Card.Body
                className="flex justify-start items-start flex-col border-none"
                style={{
                  border: "none", // Ensures no border on the card body
                }}
              >
                <Card.Title className={`text-[#0e0f12] 2x1:text-[22px] text-[9px] font-normal ${isArabic ? "" : "font-['Helvetica']"} leading-7`}>
                  {item.title}
                </Card.Title>
                <Card.Text className={`text-[#ed1d24] text-base font-normal ${isArabic ? "" : "font-['Inter'] "} leading-tight`}>
                  {item.description}
                </Card.Text>
              </Card.Body>
            </Card>
          </a>
        </div>
      ))}
    </div>

    {/* Navigation Buttons */}
    <div className={`absolute lg:top-[0%] top-4 my-4 lg:left-[90%] right-5 flex gap-2 mb-96 border-none mt-0`}>
      <button
        className="lg:w-[50px] lg:h-[50px] w-10 h-10 bg-[#ed1d24] rounded-full text-white flex justify-center items-center my-10"
        onClick={() => {
          if (autoplayInterval.current) {
            clearInterval(autoplayInterval.current); // Stop autoplay on manual navigation
          }
          handlePrev();
        }}
      >
        <MoveLeft />
      </button>
      <button
        className="lg:w-[50px] lg:h-[50px] w-10 h-10 bg-[#ed1d24] rounded-full text-white flex justify-center items-center my-10"
        onClick={() => {
          if (autoplayInterval.current) {
            clearInterval(autoplayInterval.current); // Stop autoplay on manual navigation
          }
          handleNext();
        }}
      >
        <MoveRight />
      </button>
    </div>
  </div>
);
}

export default CustomCarousel;
