import { useParams } from 'react-router-dom';
import { blogs } from '../../Data/Blogs';
import { texts } from '../../Data/DynamicBlog';
import i1 from "../../images/Blogs/1.png";
import i2 from "../../images/Blogs/2.png";
import i3 from "../../images/Blogs/3.png";
import blogimg from "../../images/Blogs/Sblog.png";
import f from "../../images/Blogs/f.png";
import i from "../../images/Blogs/i.png";
import l from "../../images/Blogs/l.png";
import x from "../../images/Blogs/x.png";
import y from "../../images/Blogs/y.png";

function DynamicBlog() {
    const { id } = useParams();
    const oneBlog = blogs[Number(id)];

    return (
        <div className="relative md:bottom-0 bottom-48 w-full bg-gradient-to-b from-[#a4e7c6] via-white to-[#ebf0ee] rounded-tr-[100px] py-14 flex justify-center">
            <div className='w-[90%] flex lg:flex-row flex-col justify-between space-y-10'>
                <div className='lg:w-[67%] rounded-2xl'>
                    <img src={oneBlog.image} alt='None' className='lg:w-full h-[270px] md:h-[700px] rounded-2xl' />
                    <div className='space-y-7'>
                        <div className='mt-10 space-y-4'>
                            <h2 className="text-[#ed1d24] text-base font-normal font-['Inter'] leading-tight">11 December 2024</h2>
                            <h1 className="text-black text-2xl lg:text-[55px] font-bold font-['Helvetica'] lg:leading-[60px]">{oneBlog.title || "5 best language exchange apps and websites in 2024"}</h1>
                            <p className="text-black text-lg font-normal font-['Inter'] leading-[30px]">Have you noticed that a growing number of people in the UAE are on the hunt for private tutors these days? That's true! People are now centred more on personalised schooling for their young ones these days. As academic achievement takes centre stage, parents have been seeking out qualified educators to help their kids obtain the most effective support possible.
                                Obtaining a private tutor licence in UAE isn’t merely checking off legal requirements it’s actually about proving you’re a professional who’s committed to delivering students with outstanding education. When you become a licensed tutor, you’re simply stating to parents, “I’m here for you to help your child perform well!” Also, it really contributes to developing your trustworthiness and creates more chances for you in this growing market.
                                In this guide, we’re going to be leading you through six fairly simple actions to get your private tutor licence in uae, so you can begin providing private tuition services safely over there. Let's jump right in!</p>
                        </div>
                        <div>
                            {texts.map((text, index) => (
                                <div key={index} className="space-y-5">
                                    {text.title && (
                                        <h2 className="text-black text-3xl font-bold font-['Inter'] leading-[44px]">
                                            {text.title}
                                        </h2>
                                    )}
                                    <p className="text-black text-lg font-normal font-['Inter'] leading-[30px]">
                                        <span dangerouslySetInnerHTML={{ __html: text.description }} />
                                    </p>
                                </div>
                            ))}
                        </div>
                    </div>
                </div>
                <div className='lg:w-[27%]'>
                    <h2 className="w-[215px] h-[46px] text-black text-[35px] font-bold font-['Helvetica'] leading-[38.50px]">Share</h2>
                    <div className='flex gap-3'>
                        <div className='flex justify-center items-center w-12 h-12 rounded-full bg-[#008847]'>
                            <img src={i} alt='none' className='w-6 h-6 object-contain' />
                        </div>
                        <div className='flex justify-center items-center w-12 h-12 rounded-full bg-[#008847]'>
                            <img src={f} alt='none' className='w-6 h-6 object-contain' />
                        </div>
                        <div className='flex justify-center items-center w-12 h-12 rounded-full bg-[#008847]'>
                            <img src={x} alt='none' className='w-8 h-10 object-contain' />
                        </div>
                        <div className='flex justify-center items-center w-12 h-12 rounded-full bg-[#008847]'>
                            <img src={l} alt='none' className='w-6 h-6 object-contain' />
                        </div>
                        <div className='flex justify-center items-center w-12 h-12 rounded-full bg-[#008847]'>
                            <img src={y} alt='none' className='w-6 h-6 object-contain' />
                        </div>
                    </div>
                    <h2 className="hidden md:block h-[46px] text-black text-[35px] font-bold font-['Helvetica'] leading-[38.50px] mt-10">Related Blogs</h2>
                    <div className='hidden lg:block space-y-8'>
                        <div className='flex lg:flex-row flex-col gap-6 lg:items-center rounded-2xl'>
                            <img src={i1} alt='none' className='w-80 h-64 lg:w-40 lg:h-40 rounded-2xl' />
                            <div className='space-y-3'>
                                <div className=" text-[#ed1d24] text-base font-normal font-['Inter'] leading-tight">October 19, 2024</div>
                                <div className="text-black text-md font-normal font-['Helvetica'] leading-7">Board of Directorss Meeting</div>
                            </div>
                        </div>
                        <div className='flex lg:flex-row flex-col gap-6 lg:items-center rounded-2xl'>
                            <img src={i2} alt='none' className='w-80 h-64 lg:w-40 lg:h-40 rounded-2xl' />
                            <div className='space-y-3'>
                                <div className=" text-[#ed1d24] text-base font-normal font-['Inter'] leading-tight">September 28, 2024</div>
                                <div className="text-black text-md font-normal font-['Helvetica'] leading-7">A beginner’s guide to descriptive adjectives in English</div>
                            </div>
                        </div>
                        <div className='flex lg:flex-row flex-col gap-6 lg:items-center rounded-2xl'>
                            <img src={i3} alt='none' className='w-80 h-80 lg:w-40 lg:h-40 rounded-2xl' />
                            <div className='space-y-3'>
                                <div className=" text-[#ed1d24] text-base font-normal font-['Inter'] leading-tight">August 23, 2024</div>
                                <div className="text-black text-md font-normal font-['Helvetica'] leading-7">9 easy ways to get real Spanish conversation practice</div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div >
    )
}

export default DynamicBlog