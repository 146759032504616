import { Edit } from "lucide-react";
import React from "react";

const ProfileBasics = () => {
  const TutorData = {
    Firstname: "Kevin",
    Lastname: "Heart",
    image: "https://via.placeholder.com/150",
    Email: "Kevin255@gmail.com",
    phone: "+971 345 346 46",
    DOB: "Sep 26, 1998",
  };

  return (
    <div className="space-y-8">
      <h1 className="text-2xl  text-[#272727] font-semibold">Profile</h1>

      {/* Profile Picture Section */}
      <div className="bg-white rounded-2xl border p-8 flex items-center gap-4">
        <div className="relative">
          <div className="w-24 h-24 rounded-full overflow-hidden bg-gray-100">
            <img
              src={TutorData.image}
              alt="Profile picture"
              className="w-full h-full object-cover"
            />
          </div>
        </div>

        <div className="flex gap-3">
          <button className="px-4 py-2 bg-[#008847] text-white rounded-xl cursor-pointer">
            Change Picture
          </button>
          <button className="px-4 py-2 bg-[#fee9e9] text-[#EE201C] rounded-xl cursor-pointer">
            Delete Picture
          </button>
        </div>
      </div>

      {/* Personal Information Section */}
      <div className="bg-white rounded-2xl border border-gray-200 p-6">
        <div className="flex justify-between items-center mb-6">
          <h3 className="text-xl font-semibold">Personal Information</h3>
          <button className="text-gray-500 border rounded-2xl px-3 py-2  hover:text-gray-700">
            <span className="flex items-center gap-1">
              Edit
              <Edit />
            </span>
          </button>
        </div>

        <div className="grid grid-cols-2 gap-6">
          <div className="space-y-2">
            <label className="text-sm font-medium text-[#898989]">
              First Name
            </label>
            <p className="font-medium text-[#272727] text-base">
              {TutorData.Firstname}
            </p>
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium text-[#898989]">
              Last Name
            </label>
            <p className="font-medium text-[#272727] text-base">
              {TutorData.Lastname}
            </p>
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium text-[#898989]">
              Email Address
            </label>
            <p className="font-medium text-[#272727] text-base">
              {TutorData.Email}
            </p>
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium text-[#898989]">Phone</label>
            <p className="font-medium text-[#272727] text-base">
              {TutorData.phone}
            </p>
          </div>
          <div className="space-y-2">
            <label className="text-sm font-medium text-[#898989]">
              Date of Birth
            </label>
            <p className="font-medium text-[#272727] text-base">
              {TutorData.DOB}
            </p>
          </div>
        </div>
      </div>

      <div className="flex justify-end">
        <button className="px-6 py-2 bg-[#008847] text-white font-medium text-sm rounded-[28px] border-[5px] border-[#66b891]">
          Save Changes
        </button>
      </div>
    </div>
  );
};

export default ProfileBasics;
