import React, { useEffect, useState } from "react";
import StudentSidebar from "../components/DashBoardStudent/StudentSideBar";
import StudentProfileCard from "../components/DashBoardStudent/StudentProfileCard";
import { Bell, Trash2 } from "lucide-react";
import axios from "axios";
import SubjectPopup from "../components/DashBoardStudent/RequestTutor1";
import InboxMessages from "../components/DashBoardStudent/InboxMessages";

const Dashboard = () => {
  const [activeTab, setActiveTab] = useState("dashboard");
  const [user, setUser] = useState<any>({});
  const [TutorData, setTutorData] = useState([]);
  useEffect(() => {
    const data = async () => {
      const res = await axios.get('https://api.nadwa-uae.com/api/v2/users/profile/user_profile/', {
        headers: {
          Authorization: `Token ${localStorage.getItem('TH_AUTH_TOKEN')}`
        }
      })
      const data = res.data.data.user
      setUser(data)
    }
    data()
  }, [])
  useEffect(() => {
    const data = async () => {
      const res = await axios.get('https://api.nadwa-uae.com/api/v2/schoolsupply/applications/', {
        headers: {
          Authorization: `Token ${localStorage.getItem('TH_AUTH_TOKEN')}`
        }
      })
      const data = res.data.results
      setTutorData(data)
    }
    data()
  }, [])
  console.log(user)
  const handleTabChange = (tab: string) => {
    setActiveTab(tab);
  };


  return (
    <div className="flex h-fit bg-gray-100">
      <StudentSidebar
        activeTab={activeTab}
        onTabChange={(tab) => handleTabChange(tab)}
      />
      <div className="flex-1 space-y-4 p-6">
        {activeTab === "dashboard" && (
          <div className="space-y-3">
            <div className="flex justify-between items-center">
              <h1 className="text-[#000606] font-normal text-3xl">Dashboard</h1>
              <div className="flex items-center gap-3">
                <div className="p-2 bg-white rounded-full w-fit">
                  <Bell />
                </div>
                <div className="flex items-center bg-white rounded-[24px] py-1 px-2 gap-2">
                  <img
                    src={user.photo}
                    alt={user.first_name}
                    className="w-9 h-9 rounded-full object-cover"
                  />
                  <h1 className="text-sm text-[#292D32] font-normal">
                    {user.first_name}
                  </h1>
                </div>
              </div>
            </div>
            <div className="grid grid-cols-1 lg:grid-cols-3 gap-6">
              <div className="col-span-2 bg-white p-4 rounded-lg shadow">
                <div className="flex items-center justify-between">
                  <div>
                    <h2 className="text-lg font-semibold">
                      Tutor Applications
                    </h2>
                  </div>
                  <div className="flex gap-3">
                    <SubjectPopup />
                    <a
                      href="/tutor"
                      className="bg-[#f2f9f6] text[#008847] p-2 no-underline rounded-md text-[#008847] border border-[#008847]"
                    >
                      Browse other Tutors
                    </a>
                  </div>
                </div>
                <div className="mt-4">
                  <table className="w-full text-left border-separate border-spacing-y-2">
                    <thead>
                      <tr>
                        <th className="p-2 border-b">Tutor</th>
                        <th className="p-2 border-b">Message</th>
                        <th className="p-2 border-b">Subject</th>
                        <th className="p-2 border-b">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {TutorData.map((job: any, index) => (
                        <tr key={index}>
                          <td className="p-2 text-[#060606] text-sm">
                            {/* <img src={job.tutor.photo} alt="tutor" className="rounded-full h-16 w-16 object-cover mb-2" /> */}
                            <span className="font-semibold text-lg">{job.tutor.name}</span>
                          </td>
                          <td className="p-2 text-[#008847] font-semibold text-lg">
                            view messages
                          </td>
                          <td className="p-2 text-[#060606] text-sm">
                            {job.job_post.subject} ({job.job_post.level})
                          </td>
                          <td className="p-2 text-[#060606] text-sm">
                            {job.applied_at.toString().slice(0, 10)}
                          </td>
                          <td
                            className="p-2 text-gray-400 w-fit font-medium text-sm cursor-pointer"
                          >
                            <a href={`https://app.nadwa-uae.com/customer/jobs/${job.job_post.id}/`}>
                              <Trash2 />
                            </a>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>
                </div>
              </div>
              <StudentProfileCard stuData={user} />
            </div>
          </div>
        )}
        {activeTab === "inbox" && (
          <InboxMessages />
        )}
      </div>
    </div>
  );
};

export default Dashboard;
