import React from 'react';
import secondSectionImage from '../../images/asserts/Website-Assets/HomePage/image1.webp';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

export default function Section2() {
  const { t, i18n }:{ t: (key: string) => string, i18n:any } = useTranslation();
  const isArabic = i18n.language === 'ar';

  return (
    <div className={`w-full py-20 lg:py-0 lg:h-[1100px] bg-gradient-to-b from-[#a4e7c6] via-white to-[#ebf0ee] lg:rounded-tr-[100px] rounded-tr-[50px] lg:-mt-24 -mt-14`}>
      <div className="flex flex-col justify-center items-center max-w-[90%] lg:max-w-[100%] mx-auto lg:gap-5 gap-4">
        <div className={`text-[#12182d] text-xl lg:text-[26px] font-bold ${isArabic ? "" : "font-['Inter']"} uppercase lg:leading-[44px] lg:pt-5 text-center`}>
          {t("section2")}
        </div>
        <div className="flex flex-row">
          <div className="flex flex-col">
            <span className={`text-[#ed1d24] text-lg font-bold ${isArabic ? "" : "font-['Inter']"} uppercase leading-[18px]`}>
              {t("Tutors")}
            </span>
            <h3 className={`text-black lg:text-[95px] font-bold ${isArabic ? "font-[YourArabicFont]" : "font-['Helvetica']"} leading-[114px] -mt-6 lg:-mt-0`}>
              10,000+
            </h3>
          </div>
          <div className="w-[1px] h-[60px] lg:h-[140px] mx-5 bg-gray-600" />
          <div className="flex flex-col">
            <span className={`text-[#ed1d24] text-lg font-bold ${isArabic ? "" : "font-['Inter']"} uppercase leading-[18px]`}>
              {t("subjecthome")}
            </span>
            <h3 className={`text-black lg:text-[95px] font-bold ${isArabic ? "font-[YourArabicFont]" : "font-['Helvetica']"} leading-[114px] -mt-6 lg:-mt-0`}>
              100+
            </h3>
          </div>
        </div>
        <div className="grid lg:grid-cols-2 grid-cols-1 max-w-[90%] mx-auto -mt-10 lg:-mt-0">
          <div className="w-full">
            <img
              src={secondSectionImage}
              className="w-full h-full flex justify-center items-center"
              alt="2nd Section"
            />
          </div>
          <div className="2xl:w-[597px] lg:w-[400px] inline-flex lg:h-[575.01px] relative flex-col justify-start items-start gap-3 pt-14 lg:px-12 max-w-[96%] mx-auto">
            <div className="justify-start items-center 2xl:gap-2.5 gap-2 inline-flex">
              <div className="w-2.5 h-2.5 -mt-1 bg-[#ed1d24] rounded-full" />
              <div className={`text-[#ed1d24] 2xl:text-lg text-md font-normal ${isArabic ? "" : "font-['Helvetica']"} uppercase 2xl:leading-[18px] leading-[6px]`}>
                {t("about")}
              </div>
            </div>
            <div className="2xl:w-[564.03px] lg:w-[456px] h-[117px] justify-center items-center inline-flex">
              <div className={`lg:w-[564.03px] text-[#12182d] 2xl:text-4xl lg:text-3xl text-2xl font-bold ${isArabic ? "" : "font-['Inter']"} 2xl:leading-[44px] leading-[33px]`}>
                {t("about2")}
              </div>
            </div>
            <div className="2xl:w-[596.22px] lg:w-[456px] w-full h-0.5 bg-gradient-to-l from-white to-[#308042]" />
            <div className="lg:pr-[22.54px] pt-4 justify-start items-center inline-flex">
              <div className={`2xl:w-[527.46px] lg:w-[400px] h-14 text-[#12182d] 2xl:text-[22px] text-[20px] font-semibold ${isArabic ? "" : "font-['Inter']"} 2xl:leading-[30px] leading-[22px] pb-5`}>
                {t("about3")}
              </div>
            </div>
            <div className={`2xl:w-[596.22px] lg:w-[400px] text-[#12182d] 2xl:text-[22px] text-[15px] font-normal ${isArabic ? "" : "font-['Inter']"} 2xl:leading-[30px] leading-[20px] mt-16 lg:mt-10`}>
              {t("about4")}
            </div>
            <Link to="/about">
              <div className="w-[172px] h-[53px] relative mt-4 2xl:mt-0">
                <div className="w-[172px] h-[53px] left-0 top-0 absolute bg-[#ed1d24] hover:bg-black transition duration-300 rounded-[45px]" />
                <div className={`left-[23px] top-[18px] absolute text-center text-white text-lg font-bold ${isArabic ? "" : "font-['Helvetica']"} capitalize leading-[18px]`}>
                  {t("aboutbt")}
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}
