import DynamicBlog from '../components/Blogs/DynamicBlog';
import BannerSection from '../components/AboutPages/Banner';
import bannerImage from '../images/Blogs/Hero.png';
import BottomSec from '../components/Blogs/BottomSec';

function SingleBlog() {
    return (
        <div className='w-full'>
            <BannerSection title={`Blogs`} image={bannerImage} />
            <DynamicBlog />
            <BottomSec/>
        </div>
    )
}

export default SingleBlog;