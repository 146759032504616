import React from 'react';
import thirdSectionImage from "../../images/asserts/Website-Assets/HomePage/bGimage3.webp";
import FormComponent from '../ui/Form';
import { useTranslation } from 'react-i18next';

export default function Section3() {
  const { t }: { t: (key: string) => string } = useTranslation();
  return (
    <section
      className="w-full h-auto min-h-[800px] flex flex-col lg:px-10 px-3 pt-10 bg-slate-950 bg-cover bg-no-repeat pb-4"
      style={{
        backgroundImage: `url(${thirdSectionImage})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className='max-w-[90%] mx-auto flex flex-col lg:gap-6 gap-4'>
        {/* Header Section */}
        <header>
          <div className="flex items-center gap-2">
            <div className="w-2.5 h-2.5 mb-2 bg-white rounded-full" />
            <h4 className="text-white text-lg font-semibold uppercase tracking-wider">
              {t('tutor')}
            </h4>
          </div>
          <h1 className="text-white 2xl:text-[65px] tlg:ext-[45px]  2xl:mt-0 mt-10 font-bold leading-tight">
            {t('tutor2')}
          </h1>
        </header>

        {/* Boxes Section */}
        <div className="flex gap-5 justify-center w-full flex-col lg:flex-row lg:mt-8">
          {[
            {
              title: t('tutorcard1h'),
              description:
                t('tutorcard1p'),
            },
            {
              title: t('tutorcard2h'),
              description:
                t('tutorcard2p'),
            },
            {
              title: t('tutorcard3h'),
              description:
                t('tutorcard3p'),
            },
          ].map((box, index) => (
            <div
              key={index}
              className="w-full md:w-[440px] h-[200px] hover:h-auto text-black bg-white rounded-lg p-6  card-hover"
            >
              <h3 className="lg:text-[35px] text-2xl font-bold leading-[40px]">
                {box.title}
              </h3>
              <p className="mt-2 lg:text-xl leading-[30px] line-clamp-3 hover:line-clamp-none ">
                {box.description}
              </p>
            </div>
          ))}
        </div>

        {/* Location Section */}
        <div className='lg:mt-10'>
          <h2 className="text-white lg:text-[32px] font-bold lg:mb-5 ">
           {t('tutor3')}
          </h2>
          <FormComponent />
        </div>
      </div>
    </section>
  );
}