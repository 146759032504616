import { useEffect } from "react";

const CalendlyWidget = () => {
  useEffect(() => {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = "https://assets.calendly.com/assets/external/widget.js";
    script.async = true;
    document.body.appendChild(script);

    return () => {
      document.body.removeChild(script); // Clean up the script when the component unmounts
    };
  }, []);

  return (
    <div
      className="calendly-inline-widget  md:w-[100%] w-[80%] md:mx-[0px] mx-[-29px]"
      data-url="https://calendly.com/hend-sabry/oktopi-schoolconnect?back=1&month=2025-01"
      style={{ minWidth: "400px", height: "700px" }}
    ></div>
  );
};

export default CalendlyWidget;
