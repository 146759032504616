import axios from "axios";
import { GraduationCap, MapPin, ShieldCheck } from "lucide-react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate, useParams } from "react-router-dom";

const TutorProfileCard = () => {
   const { t } = useTranslation();
  const [tutorData, setTutorData] = useState(null);
  const [error, setError] = useState(null);
  const [showModal, setShowModal] = useState(false); // Modal visibility
  const [modalMessage, setModalMessage] = useState(""); // Message inside modal
  const [modalAction, setModalAction] = useState(() => { }); // Action on confirm
  const { id } = useParams();

  useEffect(() => {
    const fetchTutorData = async () => {
      try {
        const res = await axios.get(
          `https://api.nadwa-uae.com/api/v2/tutor/${id}/`
        );
        setTutorData(res.data);
      } catch (error) {
        setError("Failed to fetch tutor data. Please try again later.");
      }
    };

    fetchTutorData();
  }, [id]);

  const handleModalClose = () => setShowModal(false);

  const openModal = (message, action) => {
    setModalMessage(message);
    setModalAction(() => action);
    setShowModal(true);
  };

  const handleConfirm = () => {
    modalAction();
    setShowModal(false);
  };

  const handleTryNow = () =>
    openModal(
      t('ModelMessage'),
      () => {
        window.location.href = ("https://app.nadwa-uae.com/student/register");
      }
    );

  const handleInstantBook = () =>
    openModal(
      t('ModelMessage'),
      () => {
        window.location.href = ("https://app.nadwa-uae.com/student/register");
      }
    );

  const handleSendMessage = () =>
    openModal(
      t('ModelMessage'),
      () => {
        window.location.href = ("https://app.nadwa-uae.com/student/register");
      }
    );

  if (error) {
    return (
      <div className="text-center py-10">
        <p className="text-red-500 font-bold">{error}</p>
      </div>
    );
  }

  if (!tutorData) {
    return (
      <div className="text-center py-10">
        <p className="text-gray-500">Loading tutor profile...</p>
      </div>
    );
  }

  const {
    user,
    photo,
    country,
    tagline,
    selected_subject,
    experience_years,
    qualifications,
    experience,
    school_name,
    subjects,
    enable_trial,
    enable_instant,
    response_time_display,
  } = tutorData;

  return (
    <div className="px-4 py-10 bg-gradient-to-t from-white to-[#d3eadf] shadow-sm rounded-lg">
      {/* Modal */}
      {showModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white p-6 rounded-lg shadow-lg w-[95%] sm:w-1/3">
            <h2 className="text-xl font-bold mb-4">{t('confirmation')}</h2>
            <p className="mb-6">{modalMessage}</p>
            <div className="flex justify-end gap-4">
              <button
                className="bg-gray-300 text-black py-2 px-4 rounded"
                onClick={handleModalClose}
              >
                {t('cancel')}
              </button>
              <button
                className="bg-[#008847] text-white py-2 px-4 rounded"
                onClick={handleConfirm}
              >
                 {t('confirm')}
              </button>
            </div>
          </div>
        </div>
      )}

      {/* Main Profile Card */}
      <div className="sm:p-6 flex lg:flex-row flex-col gap-6 justify-between">
        <div className="flex md:flex-row flex-col gap-6 bg-white p-6 rounded-lg shadow-sm flex-grow">
          <img
            src={photo || "default-profile.png"}
            alt="Tutor"
            className="rounded-[20px] w-52 h-52"
          />
          <div>
            <div className="flex items-center gap-2">
              <h2 className="text-2xl font-medium">
                {user.first_name} {user.last_name}
              </h2>
              {country?.name && (
                <div className="flex gap-2 items-center">
                  <div
                    className="w-8 h-8 bg-cover bg-center bg-no-repeat"
                    style={{
                      backgroundImage: `url(${country.country_icon})`,
                    }}
                  ></div>
                  <span className="text-sm font-bold text-zinc-900">
                    {country.iso_code}
                  </span>
                </div>
              )}
            </div>
            <p className="text-[#008847] font-semibold text-lg">
              {country.iso_code === "UAE"
                ? "AED"
                : country.name === "US"
                  ? "$"
                  : ""}{" "}
              {(Number(selected_subject.price_in_cents) * 0.03214816).toFixed(2)}/{" "}
              {t('')}
            </p>
            <div className="flex md:flex-row flex-col gap-4">
              <p className="text-zinc-950">
                <span className="font-bold">{t('teaches')}: </span>
                {Array.from(
                  new Set(subjects.map((subject) => subject.subject_name))
                ).join(", ")}
              </p>
              <p className="text-zinc-950">
                <span className="font-semibold">{t('Experience')}: </span>
                {`${experience_years} Years Completed` || "N/A"}
              </p>
            </div>
            <div className="flex md:w-[95%] md:flex-row flex-col justify-between">
              <p className="font-bold">{tagline}</p>
              <p className="font-bold">200+ Lessons Completed</p>
            </div>
            {school_name && (
              <div className="flex md:flex-row flex-col md:items-center gap-6 text-sm">
                <div className="flex gap-1 items-center">
                  <GraduationCap color="black" />
                  <span className="text-lg">
                    <span className="font-bold">{t('school')}: </span>
                    {school_name}
                  </span>
                </div>
                <div className="flex gap-1 items-center">
                  <MapPin color="black" />
                  <span className="text-lg">
                    <span className="font-bold">{t('tutoring')}: </span>
                    {t('online')}
                  </span>
                </div>
              </div>
            )}
          </div>
        </div>

        {/* Right Column - Actions */}
        <div className="bg-white p-7 rounded-lg shadow-sm flex-shrink-0">
          {!tutorData.is_verified && (
            <div className="flex items-center border-2 border-[#008847] gap-2 text-[#008847] w-fit p-2 rounded-xl mb-3">
              <ShieldCheck />
              <span> {t('verifiedTutor')}</span>
            </div>
          )}
          <p className="text-[#008847] font-semibold text-lg">
            {country.iso_code === "UAE"
              ? "AED"
              : country.name === "US"
                ? "$"
                : ""}{" "}
            {(Number(selected_subject.price_in_cents) * 0.03214816).toFixed(2)}/{" "}
            {t('perhour')} <br /> <span className="text-sm font-bold text-black">{t('includigVat')}</span>
          </p>
          <div className="space-y-3">
            {enable_trial && (
              <button
                className="w-full bg-[#ED1D24] text-white text-xs md:text-[18px] py-2 px-4 md:rounded-2xl rounded-3xl"
                onClick={handleTryNow}
              >
                {t('tryNow')}
              </button>
            )}
            <div className="flex md:flex-row flex-col items-center gap-2 md:gap-5">
              {enable_instant && (
                <button
                  className="w-full bg-black text-white rounded-3xl md:rounded-2xl py-2 px-4"
                  onClick={handleInstantBook}
                >
                   {t('instantbook')}
                </button>
              )}
              <button
                className="w-full bg-red-500 text-white rounded-3xl md:rounded-2xl py-2 px-4 hover:bg-red-600"
                onClick={handleSendMessage}
              >
                {t('SendMessage')}
              </button>
            </div>
            <p className="text-sm text-center text-[#008847] font-bold">
            {t('TypicallyRespond')} {response_time_display || "<24 hours"}
            </p>
          </div>
        </div>
        {/* Introduction Section */}

      </div>
      <div className="p-6 flex items-center gap-6">
        <div className="md:w-[65%] space-y-4">
          <h3 className="text-3xl font-bold">{t('introduction')}</h3>
          <p>{experience || "Experience not provided."}</p>
          <p>
            <span className="font-semibold">{t('qualification')}: </span>
            {qualifications.length > 0
              ? qualifications
                .map((qual) => `${qual.qualification} (${qual.institution})`)
                .join(", ")
              : "No qualifications listed."}
          </p>
        </div>
      </div>
    </div>
  );
};

export default TutorProfileCard;

