import { BadgeAlert } from "lucide-react";
import React, { useState } from "react";

function Pricing() {
  const [price, setPrice] = useState("");

  const handlePriceChange = (e:React.ChangeEvent<HTMLInputElement>) => {
    setPrice(e.target.value);
  };
  return (
    <div className="max-w-md  space-y-6">
      <h1 className="text-2xl text-[#272727] font-semibold ">Pricing</h1>

      <div className="space-y-5">
        <div className="flex items-center gap-2 text-sm font-medium text-gray-700">
          Please enter your preferred hourly rate
          <BadgeAlert className="text-[#008847]" />
        </div>
        <input
          type="text"
          value={price}
          onChange={handlePriceChange}
          className="w-full p-2 text-base font-medium text-[#272727] border border-gray-300 rounded-md focus:outline-none "
          placeholder="Enter postcode"
        />
      </div>
    </div>
  );
}

export default Pricing;
