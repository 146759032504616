import { ChartNoAxesColumnIncreasing, MapPin, RotateCcw } from "lucide-react";
import { Link } from "react-router-dom";
import { useFilterContext } from "../../ContextApi/FilterContext";
import img from "../../images/asserts/Website-Assets/Find a Tutor/Online_Lesson.png";
import SkeletonLoader from "./Loading";
import axios from "axios";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import i18n from "../../i18n";
const TutorCard = ({ tutor }: any) => {
  const { t }: { t: (key: string) => string } = useTranslation();

  return (
    <div className="bg-transparent w-full h-full">
      <div>

      <a
          href={`/Tutor/${tutor.id}`}
          
        >
         <img
        src={tutor?.photo}
        alt={tutor?.name}
        className="object-contain rounded-2xl w-[350px] h-[350px]"
      />
        </a>
        
      </div>
     
      <div className="pt-4 flex flex-col xl:gap-1">
        <div className="flex justify-between gap-4">
          <div className="flex flex-col">
            <h3 className="text-xl font-bold flex gap-2 items-center">
              {tutor?.name}{" "}
              <img
                src={tutor.country?.country_icon}
                alt="icon"
                className="w-5 h-5"
              />
            </h3>
            <p className="text-black text-[16px] line-clamp-2">
              {tutor?.experience}
              <Link to="/">see more</Link>
            </p>
            <p className="text-black text-[16px] flex gap-1">
              <ChartNoAxesColumnIncreasing className="text-[#008847]" />{" "}
              {tutor?.experience_years} {t("yearsExperi")}
            </p>
          </div>
          <div className="flex flex-col">
            <p className="font-bold">
              <span className="font-semibold">
                {new Intl.NumberFormat("en-AE", {
                  style: "currency",
                  currency: "AED",
                }).format(tutor.selected_subject?.price_in_cents / 100)}
              </span>{" "}
              <br />{" "}
              <span className="text-[#008847] text-sm text-end">
                {t("perhour")}
              </span>
              <br />
              <span className="text-sm">({t("includigVat")})</span>
            </p>
          </div>
        </div>
        <div className="flex justify-between">
          <p className="text-[15px]  text-black flex gap-2 items-center">
            <span>
              <img src={img} alt="" className="w-5 h-5" />
            </span>
            {t("lesson")}
          </p>
          <span className="text-sm flex gap-1">
            <MapPin className="text-[#008847] h-5 w-4" />
            {t("tutoring")}:{" "}
            {tutor?.online_tutoring ? "Online and In-Person" : t("online")}
          </span>
        </div>
        {/* Resolved Linked */}
        <a
          href={`/Tutor/${tutor.id}`}
          className={ `bg-red-500 text-center no-underline text-white py-2 rounded-3xl hover:bg-red-600 transition ${i18n.dir(i18n.language) === 'rtl' ? 'w-44' : 'w-36'} `}
        >
          {t("Viewprofile")}
        </a>
      </div>
    </div>
  );
};
const TutorsResult = () => {
    const { t }: { t: (key: string) => string } = useTranslation();
  const [pageNumber, setPageNumber] = useState(2);
  const { otherFilters, loading, setOtherFilters, setLoading } =
    useFilterContext();

  const getNextdata = async () => {
    try {
      setLoading(true);
      const res = await axios.get(
        `https://api.nadwa-uae.com/api/v2/tutor/?subject_id=&levelId=&country=&max_price=200&min_price=100&max_experience=60&min_experience=0&postcode=Online&online=true&search=&page=${pageNumber}`
      );
      setOtherFilters(res.data.results);
    } catch (error) {
      console.log(error);
    } finally {
      setPageNumber(pageNumber + 1);
      setLoading(false);
    }
  };
  return (
    <div className="relative py-20">
      {loading ? (
        <SkeletonLoader />
      ) : (
        <div>
          <h2 className="text-3xl font-bold mb-8 ml-20">
            {" "}
            {t("SearchReults")}
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-16 xl:gap-14 max-w-[92%] mx-auto">
            {otherFilters.length > 0 ? (
              otherFilters.map((tutor: any) => (
                <TutorCard key={tutor.id} tutor={tutor} />
              ))
            ) : (
              <p className="text-center text-2xl text-black">{t("notfound")}</p>
            )}
          </div>
          <span
            className="flex flex-col gap-2 items-center justify-center mt-14 cursor-pointer"
            onClick={getNextdata}
          >
            <RotateCcw className="text-[#ED1D24]" /> {t('LoadMore')}
          </span>
        </div>
      )}
    </div>
  );
};

export default TutorsResult;
